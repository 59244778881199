@media (min-width: 1024px) {
    .lg\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
}

.text-dark-30 {
    --tw-text-opacity: 1;
    color: rgba(16, 24, 40, var(--tw-text-opacity));
}

.font-extrabold {
    font-weight: 800;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

@media (min-width: 1024px) {
    .lg\:text-5xl {
        font-size: 3rem;
        line-height: 1;
    }
}

.min-w-\[18px\] {
    min-width: 18px;
}

.mr-2 {
    margin-right: .5rem;
}

.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}

@media (min-width: 768px) {
    .md\:py-\[70px\] {
        padding-bottom: 70px;
        padding-top: 70px;
    }
}

@media (min-width: 640px) {
    .sm\:py-16 {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }
}


.py-12 {
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.max-w-6xl {
    max-width: 72rem;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}


@media (min-width: 1024px) {
    .lg\:leading-\[56px\] {
        line-height: 56px;
    }
}

@media (min-width: 1024px) {
    .lg\:text-\[42px\] {
        font-size: 42px;
    }
}

@media (min-width: 768px) {
    .md\:text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
}

@media (min-width: 640px) {
    .sm\:text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
}

.tab-item {
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid transparent;
}

.tab-item.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.menu-content {
  padding: 20px;
  border-top: 1px solid #ddd;
}