/* Container for each template row */
.template-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0; /* Add space around the row */
    border-bottom: 1px solid #e0e0e0; /* Optional: Add a border between rows */
  }
  
  /* Template name (left aligned) */
  .template-name {
    font-size: 14px; /* Small font size */
    flex-grow: 1; /* Take remaining space */
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Ellipsis if the name is too long */
  }
  
  /* Actions (right aligned) */
  .template-actions {
    display: flex;
    gap: 10px; /* Space between the icons */
  }
  
  /* Button styles */
  .icon-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  /* Optional: Change icon hover effect */
  .icon-button:hover .mdi {
    opacity: 0.7; /* Makes icon slightly transparent on hover */
  }
  
.w-full {
    width: 100% !important;
}

.justify-between {
  justify-content: space-between !important;
}

.items-start {
  align-items: flex-start !important;
}

.flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column !important;
}

.max-w-sm {
  max-width: 24rem !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.text-base {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.font-medium {
  font-weight: 500 !important;
}

.w-fit {
  width: fit-content !important;
}

.justify-end {
  justify-content: flex-end !important;
}

