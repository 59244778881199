
.css-1tsc6yp span[role="img"]:nth-child(2) {
display: none;
}

.css-1tsc6yp {
position: relative;
flex-shrink: 0;
display: inline-block;
width: 16px;
height: 16px;
vertical-align: middle;
}
.css-10ewgza:not(#mdn-specificity-hack) {
    font-weight: 600;
    font-size: 10px;
    line-height: 24px;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    }

.css-34iy07 {
font-size: 0px;
}
.css-34iy07 svg {
position: absolute;
top: 50%;
left: 50%;
transform: translateY(-50%) translateX(-50%);
}

.css-sq80gj {
    padding: 0px;
    border: 0px;
    text-align: inherit;
    margin-block: 0px;
    margin-inline: 0px;
    cursor: pointer;
    font-family: "Amazon Ember", "Amazon Ember Arabic", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #333c4d;
    background-color: rgba(0, 0, 0, 0);
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    outline: none;
    height: 32px;
    width: 32px;
    flex-shrink: 0;
    transition: color 100ms ease 0s, background-color 100ms ease 0s, border-color 100ms ease 0s;
    border-radius: 4px;
    white-space: nowrap;
    overflow: visible;
    position: relative;
    z-index: 0;
    }
  .css-sd2sfag3{
  border: 1px solid #e3e3e3;
  font-size: 10px;
  }
  a.nav-link.css-roster.active{
  color: #333c4d;
  background-color: #f2f2f2;
  }
  .css-1m5o92us {
  -webkit-box-align: center;
  align-items: center;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: #41a5e5;
  color: rgb(255, 255, 255);
  font-size: 10px;
  min-height: 30px;
  min-width: 80px;
  padding: 4px 15px;
  font-weight: 500;
  line-height: 0.5;
  }
  .css-1m5o92us:hover{
  background-color: #1685ca;
  }
  .css-1m5o92sus:hover{
  background-color: #1685ca;
  }
  .css-1m92us:hover{
  background-color: #69bdf1;
  color: #ffffff;
  }
  .css-1m92us {
  -webkit-box-align: center;
  align-items: center;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: #e8f2ff75;
  color: #667085;
  font-size: 11px;
  min-height: 40px;
  
  padding: 4px 15px;
  font-weight: 500;
  line-height: 0.5;
  }
  .css-1m5o92sus {
  -webkit-box-align: center;
  align-items: center;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: #41a5e5;
  color: rgb(255, 255, 255);
  font-size: 16px;
  min-height: 30px;
  
  padding: 4px 15px;
  font-weight: 500;
  line-height: 0.5;
  }
  .css-23dsfxs{
  margin: 1px 9px 0px;
  height: 14px;
  width: 14px;
  font-size: 14px;
  background-repeat: no-repeat;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  vertical-align: -15%;
  }
  .css-230ofxs{
  margin: 1px 9px 0px;
  height: 10px;
  width: 10px;
  font-size: 12px;
  background-repeat: no-repeat;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  vertical-align: -15%;
  }

  .css-230ofxs_total{
    margin: 1px 9px 0px;
    height: 10px;
    width: 10px;
    font-size: 18px;
    background-repeat: no-repeat;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    fill: currentColor;
    vertical-align: -15%;
    }
  .css-23dsfxsu{
  height: 14px;
  /* width: 14px; */
  font-size: 28px;
  background-repeat: no-repeat;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  vertical-align: -15%;
  }
  
  .css-f3o40sd3.row {
  padding: 5px;
  border-bottom: 1px solid #eaecf0;
  box-shadow: 0 1px 5px #00000020;
  height: 500px;
  overflow: scroll;
  width: 100%;
  }
  
  .blue-sv232{
  background-color: #e8f4fd;
  margin-bottom: 3px;
  justify-content: center;
  align-items: center;
  height: 40px;
  }
  .css-k23serv3s{
  display: inline-flex;
  margin-top: auto;
  vertical-align: -15%;
  
  }
  .css-sa3422a{
    display:grid;
    margin-top: auto;  
    }
  .css-li23s54 {
    width: 200px;
    /* height: 36px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px;
    font-weight: 600;
    font-size: 12px;
  }

  .css-li23s54-sheet {
    width: 200px;
    /* height: 36px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px;
    font-weight: 600;
    font-size: 12px;
  }
  .css-s3o0sad3 {
    width: 100px;
    display: block;
      text-align: center;
      position: relative;
  }
  .css-block{
    width: 100px;
  }
  .css-s3o0fff3{
    font-weight: 600;
      cursor: pointer;
      font-size: 10px;
  }
  .border-style{
    border: 1px solid rgb(237 237 237);
    border-top: none;
  }
  .wrap-css0o8{
  width: 100%;
  border: 3px solid #6392df;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  cursor: pointer;
  border-radius: 4px;
  }
  .css-266sd3{
    background: #6392df;
    height: 100%;
    width: 11px;
      border-radius: 10em;
  }
  .css-26fs42g{
    position: relative;
    display: block;
    justify-content: center;
    left: 13px;
  }
  .time-as23oo2s{
    display: inline-flex;
    position: relative;
    width: 54px;
    top: 4px;
    right: 10px;
    /* justify-content: center; */
    font-weight: 700;
    font-size: 12px!important;
    text-overflow: ellipsis!important;
    overflow: hidden!important;
    color: #333c4d!important;
  }
  .time-as23oo{
    position: relative;
    right: 14px;
    width: 49px;
    bottom: 2px;
    font-weight: 700;
    font-size: 12px!important;
    text-overflow: ellipsis!important;
    overflow: hidden!important;
    color: #333c4d!important;
    display: inline-flex;
  }
  .css-266sd3s3{
    position: relative;
      display: inline-flex;
      background: #fb002e;
      font-size: 12px;
      color: white;
      height: 27px;
      width: 43px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
  }
  .edit-vds3g{
    height: 36px;
    padding: 2px;
    position: relative;
  
  }
  .css-shord3{
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 0px 1px 2px 1px;
 z-index: 3000;
  background: #ffffff;
  border: 2px solid rgb(107 105 105);
  }

  .css-shord3:hover{
    background: #ffffff;
     border: 2px solid rgb(107 105 105);
  }
  .section-f23{
    width: 100%;
    /* border: 0px solid #6392df; */
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    cursor: pointer;
    background: #f3f8fe;
  }
  
  .add-40os34{
    width: 100%;
    height: 100%;
  }
  .add-plus{
    height: 100%;
  }
  .diens-content{
    height: 100%;
    border: none;
    border-radius: 0px;
  }
  .diens-header{
   background: #5ecd6c;
  height: 3em;
  border: none;
  border-radius: 0px;
  }
  .diens-title{
    color: #fdfdfd;
  }
  .css-modo087{
    padding: initial;
  }
  .department {
    position: fixed;
    top: 30px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1055;
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  .department_site{
    position: absolute;
      right: 5px;
      width: 252px;
      height: 100%;
      top: 75px;
  }
  .close_are{
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 29%;
    color: white;
    font-weight: 600;
    border: 1px solid;
    cursor: pointer;
  }
  .modal-section {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    top: 7px;
  }
  .search-container{
    display: inline-flex;
  }
  .css-smal4s9 {
    -webkit-box-align: center;
    align-items: center;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-flex;
    font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: normal;
    margin: 0px;
    overflow: visible;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
    vertical-align: middle;
    background-color: #41a5e5;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    line-height: 0.5;
    margin-left: 5px;
  }
  #map-canvas {
    display: inline-flex;
    height: 300px;
    width: 100%;
    margin-left: auto;
  }
  .select_options{
    display: block;
      position: relative;
      top: 7px;
  }
  
  .grid_pay_plane {
    padding: 0;
    width: 100%;
    }  
    /* Card Styles */
    .card_pay_plane {
      background: #fff;
      border: 1px solid #e2ebf6;
      border-radius: 0.25em;
      cursor: pointer;
      display: inline-flex;
      padding: 3px;
      position: relative;
      transition: all 0.2s;
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .card_pay_plane:hover {
    border-color: #c4d1e1;
    box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.15);
    transform: translate(-4px, -4px);
    }
    
    .card__image_pay_plane {
    border-radius: 0.25em;
    height: 6em;
    min-width: 6em;
    }
    
    .card__content_pay_plane {
    flex: auto;
    padding: 0 1em;
    display: inline-flex;
    }
    .checkbox-control_pay_plane{
          display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .time_cost{
      padding-right: 15px;
      position: relative;
      top: 7px;
    }
    .card_pay_plane h2 {
    font-weight: 700;
    margin: 0;
    }
    
    .card_pay_plane p {
    color: #546e7a;
    margin: 0;
    }
    .check_Driver_deatails_plane{
    color: #1d8f00;
    }
    /* Checkbox Styles */
    .checkbox_pay_plane {
      cursor: pointer;
      background: #e2ebf6;
      border-radius: 50%;
      height: 1em;
      margin: 0;
      margin-left: auto;
      flex: none;
      outline: none;
      position: relative;
      transition: all 0.2s;
      width: 2em;
      top: 7px;
    }
    .checkbox_pay_plane:after {
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: "";
    display: block;
    height: 1em;
    left: 0.625em;
    position: absolute;
    top: 0.25em;
    transform: rotate(45deg);
    width: 0.5em;
    }
    .checkbox_pay_plane:focus {
    box-shadow: 0 0 0 2px rgba(100, 193, 117, 0.6);
    }
    .checkbox_pay_plane:checked {
    background: #64c175;
    border-color: #64c175;
    }
    
    .checkbox-control__target_pay_plane {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    }
    .header-profile-user_plane {
      height: 26px;
      width: 26px;
      background-color: #f6f6f6;
      padding: 3px; }
  
      .medewerkers{
        overflow-y: auto;
        max-height: 200px;
      }
      .btnmod{
        display: flex;
      }
      .schedultitle{
      position: relative;
      top: 1px;
      left: 11px;
      }
      .section_overal{
        position: relative;
      right: 12px;
      }
      .section_teams{
        position: relative;
        /* right: 12px; */
      }
      .css-spanfs3{
      border: 1px solid #0c0c0c;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      font-size: 32px;
      }
      .css-23fs434{
        display: inline-flex;
      justify-content: center;
      align-items: center;
  }
  .sectionTitle{
    padding: 5px 0px 5px 0px;
      background-color: #f2f4f7;
      position: sticky;
      left: 1px;
  }
  .ex-css3s4{
    width: 20%; background-color: #fff!important;
    color: #667085!important;
     border: none!important;
  }
  .seconRow{
    display: flex;
  }
  .sectionTitle_top{
    position: fixed;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding: 5px 0px 5px 0px;
  }
  .seconRow_top{
    display: flex;
  }
  
  .content-header{
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    z-index: 980;
    min-height: 55px;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    background-color: #fff;
    flex-wrap: wrap;
    border-bottom: 1px solid #eaecf0;
    box-shadow: 0 1px 5px #00000020;
    height: 54px;
  }
  .content-header__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    flex-grow: 1;
    flex-basis: 0;
    text-align: right;
    padding-left: 12px;
    border: none;
    padding-right: 12px;
  }
  .flex-1 {
    flex: 1 1 0%!important;
  }
  .flex {
    display: flex!important;
  }
  .btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
  }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle),
  .btn-group > .content-state--button:first-child:not(:last-child):not(.dropdown-toggle),
  .btn-group > .attachment-list-item__button:first-child:not(:last-child):not(.dropdown-toggle),
  .btn-group > .sub-header__button:first-child:not(:last-child):not(.dropdown-toggle),
  .btn-group > .sub-header__button-link:first-child:not(:last-child):not(.dropdown-toggle),
  .btn-group > .button-large-status-icon__loading:first-child:not(:last-child):not(.dropdown-toggle),
  .btn-group > .button-status-icon__loading:first-child:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-orange:first-child:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-large:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .hidden-md-up {
    display: none!important;
  }
  .content-header__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 12px;
    margin-left: 12px;
  }

  .css-10ewgza {
    font-family: "Amazon Ember", "Amazon Ember Arabic", Arial, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #333c4d;
    margin: 0px;
    }
  @media screen and (max-width: 980px) {
   p .css-10ewgza {
      display: none; }
  }
   .page__contentB{
    width: 100%;
    background-color: #fcfcfd;
    padding: 0px;
    position: absolute;
    left: 0;
    right: 0;
    top: 107px;
   }
   .card__schedule-optimized {
    border: 0;
    margin-top: 0;
  }
  .card, .comment-list__item, .app-block, .dash-block, .card__no-border, .card__no-padding {
    margin: 12px 0 0;
  }
  .card, .comment-list__item, .app-block, .dash-block, .card__no-border, .card__no-padding {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #eaecf0;
    border-radius: 3px;
    top: 9px;
    overflow: hidden;
  }
  .block-border-radius {
    border-radius: 3px 3px 0 0;
  }
  .block-style-rows {
    display: block;
    position: sticky;
      left: 0;
  }

  
  .card-header:first-child, .app-block__header:first-child, .dash-block__header:first-child, .card-heading:first-child {
    border-radius: 3px 3px 0 0;
  }
  .department-header-border {
    border-top: 1px solid #dbe3f1;
  }
  .card-heading__table {
    z-index: 1030!important;

  }
 
  

 
  .right-slider-diens{
    position: fixed;
    display: block;
    width: 284px;
    height: 420px;
    background: #ffffff;
    z-index: 1000;
    padding: 13px;
    top: 180px;
  }
  .edit_icon{
    position: relative;
      display: inline;
      float: right;
      top: 3px;
  }
  .timeOfDiensRow{
      border-radius: 8px;
  }
  .section_search{
    display: inline-flex;
    justify-content: center;
    padding: 10px 22px;
  }
  .card-heading {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f4f7;
    padding: 5px 19px 5px 5px;
  }
  .card-header, .app-block__header, .dash-block__header, .card-heading {
    padding: 0.4375rem;
    margin-bottom: 0;
    background-color: #f2f4f7;
    border-bottom: 1px solid #eaecf0;
  }
  .sticky-department-header-left-col {
    position: sticky;
    left: 0;
  }
  .sticky-department-header-right-col {
    position: sticky;
    right: 20px;
    display: block;
  }
  
  .card-heading__no-padding {
    padding: 0;
  }
  .card-heading__title {
    display: flex;
    align-items: center;
    padding: 0 7px;
    line-height: 33px;
    color: #333c4d;
  }
  .btn-large, .content-state--button, .button-icon-text {
    vertical-align: middle;
  }
  .button-large-icon__icon, .button-icon__icon {
    color: inherit;
    vertical-align: middle;
  }
  .si, .card-heading__button-icon {
    height: 14px;
    width: 14px;
    font-size: 14px;
    background-repeat: no-repeat;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    fill: currentColor;
    vertical-align: -15%;
  }
  .btn-white {
    background-color: #fff!important;
    color: #667085!important;
    border: none!important;
  }
  .modal{
    z-index: 3000;
  }
  .departments {
    display: flex;
    height: 100%;
  
  }
  .departments .schedule {
    flex-grow: 1;
  }
  .hidden-scroll {
    overflow: hidden;
  }
  .grid-full-width {
    width: 100%;
  }
  .header-scroller {
    width: 100%;
    overflow: hidden;
    will-change: scroll-position;
  }
  .static-team-header {
    width: -moz-fit-content;
    width: fit-content;
    min-width: 100%;
  }
  
  .top_reels{
    position: relative;
  }
  .sticky_fris{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .sticky_day{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 999;
  }
  
  .top-sticky{
    position: relative;
    z-index: 990;
  }
  .schedule-status-bar {
    height: 44px;
    background-color: #eaeff8;
    width: calc(100% - 75px);
    position: fixed;
    bottom: 0px;
    z-index: 10;
    border: 1px solid #dbe3f1;
    border-radius: 3px;
  }
  .items-center {
    align-items: center!important;
  }
  .sb-text-word-mark-800 {
    color: #333c4d!important;
  }
  .schedule-status-bar .status-container schedule-status-block {
    border-radius: 3px;
    margin: 5px;
    display: flex;
  }
  .schedule-status-bar .status-container {
    display: flex;
    justify-content: space-around;;
    align-items: center;
    font-weight: 600;
    text-align: center;
    padding: 0 5px;
    height: 100%;
  }
  .ng-star-inserted{
    border-radius: 3px;
    /* display: flex; */
  }
  .rounded-full {
    border-radius: 9999px!important;
  }
  .justify-center {
    justify-content: center!important;
  }
  .w-\[25px\] {
    width: 25px!important;
  }
  .h-\[25px\] {
    height: 25px!important;
  }
  .mr-1\.5 {
    margin-right: 0.375rem!important;
  }
  .sb-bg-error-500 {
    background-color: #f04438!important;
  }
  .sb-bg-success-500 {
    background-color: #00b55a!important;
  }
  .clickable {
    cursor: pointer;
  }
  .flex-column-schedule {
    display: flex;
    flex-direction: column;
  }
  .grid-full-height, [awesome-grid-center], [awesome-grid-left], [awesome-grid-right] {
    height: 100%;
  }
  .dropzone-day .day-drop-list {
    overflow: hidden;
    flex-grow: 1;
  }
  .schedule-template {
    display: block;
    margin: 3px 0;
    width: 100%;
  }
  .border-solid {
    border-style: solid!important;
  }
  
  .h-\[34px\] {
    height: 34px!important;
  }

  .h-\[36px\] {
    height: 36px!important;
  }

  .h-\[40px\] {
    height: 40px!important;
  }
  .sh-tl__conflicts {
    background-image: linear-gradient(45deg,rgba(141,128,128,.15) 25%,transparent 25%,transparent 50%,rgba(113,113,113,.15) 50%,rgba(115,111,111,.15) 75%,transparent 75%,transparent);
    background-size: 16px 16px
  }
  .rounded {
    border-radius: 0.25rem;
  }
  .top-0 {
    top: 0!important;
  }
  .left-0 {
    left: 0!important;
  }
  .left-20 {
    left: 20px!important;
  }
  .relative {
    position: relative!important;
  }
  .css_start-eind {
    bottom: 3px;
  }
  .rounded-full {
    border-radius: 9999px!important;
  }
  .min-w-\[6px\] {
    min-width: 6px!important;
  }
  .w-\[6px\] {
    width: 6px!important;
  }
  .h-\[26px\] {
    height: 26px!important;
  }
  .top-\[-13px\] {
    top: -13px!important;
  }
  .ml-\[2px\] {
    margin-left: 2px!important;
  }
  .absolute {
    position: absolute!important;
  }
  .border-\[2px\] {
    border-width: 2px!important;
  }
  .rounded {
    border-radius: 0.25rem!important;
  }
  .h-\[34px\] {
    height: 34px!important;
  }
  .top-\[-13px\] {
    top: -13px!important;
  }
  .sh-tl__static {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 2px;
    height: 100%;
    flex-flow: row wrap;
    border-radius: 3px;
    color: inherit!important;
    font-size: 12px;
  }
  .w-full {
    width: 100%!important;
  }
  .pr-2 {
    padding-right: 0.5rem!important;
  }
  .justify-between {
    justify-content: space-between!important;
  }
  .w-\[calc\(100\%-10px\)\] {
    width: calc(100% - 10px)!important;
  }
  .h-full {
    height: 100%!important;
  }
  .ml-3 {
    margin-left: 0.75rem!important;
  }
  .h-full {
    height: 100%!important;
  }
  .mt-\[-1px\] {
    margin-top: -1px!important;
  }
  .sb-text-word-mark-800 {
    color: #333c4d!important;
  }
  .font-bold {
    font-weight: 700!important;
  }
  .text-xs {
    font-size: .625rem!important;
    line-height: .875rem!important;
  }
  .text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis!important;
  }
  .width-40px{
    width: 40px;
  }
  .width-60px{
    width: 60px;
  }
  .width-75px{
    width: 75px;
  }
  .width-29px{
    width: 29px;
  }
  .width-24px{
    width: 24px;
  }
  .p-r2{
    position: relative;
    right: 3px;
  }
  
  .overflow-hidden {
    overflow: hidden!important;
  }
  .sb-text-grey-600 {
    color: #000205!important;
  }
  .sh-tl__static--icons {
    display: flex;
    position: relative;
    left: 4px;
  }
  .last\:mr-0:last-child {
    margin-right: 0!important;
  }
  
  
  .last\:mr-0:last-child {
      margin-right: 0!important;
  }
  @media (min-width: 1024px){
    .lg\:flex {
      display: flex!important;
  }
  }
  .text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255/var(--tw-text-opacity))!important;
  }
  .bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255/var(--tw-bg-opacity))!important;
  }
  .sh-tl__btn-icon {
    height: 15px;
    width: 15px;
    color: inherit!important;
  }
  .rounded-full {
    border-radius: 9999px!important;
  }
  .left_7{
    left: 7px;
  }
  .new_wraper {
    position: absolute;
  }
  .new-btn_group{
    position: absolute;
      display: inline-flex;
      vertical-align: middle;
      
      left: 1px;
      z-index: 10;
      width: 100%;
  }

  .new-btn_group:hover{
 opacity: 1;
  }
  .trasmodal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    background: #00000080;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 3000;
  }
  .fades {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
  }
  .addmodal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    background: #00000080;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 3000;
  }
  .fadeadd {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
  }
  
  @keyframes spinner-rotate {
      100% {
          transform: rotate(1turn);
      }
  }
  .css-f23as4{
    background-color: #c7c7c74d;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    z-index: 3000;
  }
  .spinner-page {
      position: relative;
      z-index: 1010;
      width: 96px;
      height: 61px;
      border-radius: 8px;
      overflow: hidden;
      padding: 2rem;
      &::before {
          content: '';
          position: absolute;
          z-index: -2;
          left: -50%;
          top: -50%;
          width: 200%;
          height: 200%;
          background-color: #007bff;
          background-repeat: no-repeat;
          background-size: 50% 50%, 50% 50%;
          background-position: 0 0, 100% 0, 100% 100%, 0 100%;
          background-image: linear-gradient(#007bff, #007bff), linear-gradient(#b3d7ff, #b3d7ff), linear-gradient(#b3d7ff, #b3d7ff), linear-gradient(#b3d7ff, #b3d7ff);
          animation: spinner-rotate 0.75s linear infinite;
      }
      
      &::after {
          content: '';
          position: absolute;
          z-index: -1;
          left: 6px;
          top: 6px;
          width: calc(100% - 12px);
          height: calc(100% - 12px);
          background: white;
          border-radius: 4px;
      }
  }
  .open_icon{
    left: 72px;
      bottom: 28px;
  }
  .loadingPage{
    z-index: 1050;
  }
  .css-16cz7xa{
    border-bottom: 1px solid rgb(204, 204, 204);
      display: flex;
      flex-direction: row;
      min-height: 41px;
      width: 100%;
      -webkit-box-align: center;
      align-items: center;
      flex: 0 0 auto;
      position: relative;
      justify-content: center;
      background-color: rgb(233, 233, 233);
  }
  .css-1apspd7 {
    display: inline-flex;
    vertical-align: middle;
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid rgb(204, 204, 204);
  }
  
  .css-1m1df6c {
    background-color: rgb(36, 99, 209);
    color: rgb(255, 255, 255);
    line-height: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
    border: rgb(36, 99, 209);
  }
  .css-5m2df2c {
    display: inline-flex;
    vertical-align: middle;
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid rgb(204, 204, 204);
    position: relative;
    left: 12rem;
  }
  .css-5m2df2c-signer {
    display: inline-flex;
    vertical-align: middle;
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid rgb(204, 204, 204);
    position: relative;
    left: 1rem;
  }
  .css-zf56fy {
    display: inline-flex;
    line-height: 0;
  }
  .css-guny5b {
    border: 0px;
    height: 1px;
    width: 1px;
    clip: rect(0px, 0px, 0px, 0px);
    position: absolute;
  }
  .css-1jbj2f5 {
    background-color: rgb(255, 255, 255);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 2px;
    padding-left: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
    line-height: 20px;
    width: 100%;
    min-height: 28px;
    font-size: 13px;
    border: 1px solid rgb(204, 204, 204);
  }
  
  .css-e6v92s {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }
  .css-tkbcgh {
    display: flex;
    list-style: none;
    margin: 0px;
    overflow-x: auto;
    padding: 0px;
  }
  .listHeaderWrap {
    background-color: #fff;
  }
  .css-jcpkb3 {
    min-height: 80px;
  }
  @media (min-width: 768px){
    .listHeaderWrap-templates .listHeader_titleRow {
      display: flex;
      justify-content: space-between;
  }
  }
  .listHeader_titleRow {
    padding: 0 24px 14px 24px;
  }
  .listHeader_heading {
    line-height: 1;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  .m-bottom-xx-small {
    margin-bottom: 8px!important;
  }
   .vh {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  @media (min-width: 768px)
  {.listHeaderWrap-templates .listHeader_filter {
      min-width: 450px;
      position: relative;
  }}
  
  
  @media (min-width: 768px)
  {.cheshire-templates {
      border-right: none;
      border-top: none;
      max-width: 469px;
      padding: 24px 0 17px;
      position: absolute;
      right: -24px;
      top: -24px;
  }}
  .cheshire-templates .cheshire_visibleContent.cheshire_visible-active {
    z-index: 0;
  }
  .cheshire_visibleContent.cheshire_visible-active {
    position: relative;
    z-index: 3;
  }
  
  .cheshire-templates .cheshire_visibleContent.cheshire_visible-active {
      z-index: 0;
  }
  
  .cheshire_visibleContent.cheshire_visible-active {
      position: relative;
      z-index: 3;
  }
  
  .m-left-large {
      margin-left: 24px!important;
  }
  
  .templates_filterBtn {
    margin-right: 4px;
  }
  .listHeader_search {
    display: inline-block;
    margin-right: 14px;
    width: 300px;
  }
  .css-5gs0ys {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    overflow-wrap: break-word;
    color: rgb(30, 30, 30);
    display: block;
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .css-k008qs {
    display: flex;
  }
  .css-1eq84o {
    display: flex;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(134, 134, 134);
    border-radius: 2px;
    width: 100%;
    outline: transparent solid 1px;
    outline-offset: -2px;
    transition-duration: 100ms;
    transition-property: border, outline;
    max-height: 28px;
  }
  .css-1nenmzo {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    flex-shrink: 0;
    min-width: 30px;
    color: rgb(134, 134, 134);
  }
  .css-1ez4hss {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-overflow: ellipsis;
    width: 100%;
    appearance: none;
    background: transparent;
    border: none;
    color: rgb(30, 30, 30);
    height: auto;
    margin: 0px;
    padding-top: 4px;
    padding-bottom: 5px;
    padding-inline: 0px 8px;
    outline: none;
  }
  .css-zf56fy {
    display: inline-flex;
    line-height: 0;
  }
  .css-qhnch2 {
    font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 18px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 2px;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.66px;
    margin: 0px;
    overflow: visible;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
    vertical-align: middle;
    background-color: transparent;
    color: rgb(30, 30, 30);
    font-size: 12px;
    min-height: 28px;
    min-width: 28px;
    padding: 4px 14px;
  }
  .css-1pig3b7 {
    line-height: 0.66px;
    pointer-events: none;
    margin-inline: -4px 6px;
  }
  .css-zf56fy {
    display: inline-flex;
    line-height: 0;
  }
  .css-16hz1ch {
    pointer-events: none;
  }
  .css-1pdgtej {
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .css-1u2njnl {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    background-color: rgb(255, 255, 255);
    border-collapse: separate;
    border-spacing: 0px;
    border-top: 1px solid rgb(233, 233, 233);
    color: rgb(30, 30, 30);
    width: 100%;
  }
  .css-1u2njnl tr:last-of-type th:nth-of-type(1) {
    width: 2px;
    text-align: start;
  }
  .css-17bqg1m:first-of-type {
    border-inline-start: 3px solid transparent;
    padding-inline-start: 21px;
  }
  .css-17bqg1m {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 0px 12px;
    text-transform: inherit;
    line-height: 0 !important;
  }
  .css-11jaa7e {
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .css-1u2njnl tr:last-of-type th:nth-of-type(3) {
    width: 55%;
    text-align: start;
  }
  .css-rhk3of {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 8px 12px;
    text-transform: inherit;
  }
  .css-1u2njnl tr:last-of-type th:nth-of-type(4) {
    width: 20%;
    text-align: start;
  }
  .css-3div8j {
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
  }
  .css-1u2njnl tr:last-of-type th:nth-of-type(5) {
    width: 13%;
    text-align: start;
  }
  .css-3div8j {
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
  }
  .css-1k5fhov {
    fill: rgb(134, 134, 134);
    display: flex;
    flex-shrink: 0;
    margin-inline-start: 4px;
  }
  .css-zf56fy {
    display: inline-flex;
    line-height: 0;
  }
  .css-17bqg1m:last-child {
    border-inline-end: none;
    padding-inline-end: 24px;
  }
  .css-11jaa7e {
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .css-1obf64m {
    display: contents;
  }
  .css-cir4u5 {
    background-color: rgb(255, 255, 255);
    transition: background-color 0.2s ease 0s;
  }
  .css-cir4u5 td:first-of-type {
    border-inline-start-color: rgb(36, 99, 209);
  }
  
  .css-1u2njnl td {
    text-align: start;
  }
  .css-1u2njnl td:nth-of-type(1) {
    width: 2px;
    text-align: start;
  }
  .css-85irbq:first-of-type {
    border-inline-start: 3px solid transparent;
    padding-inline-start: 21px;
  }
  .css-1u2njnl td {
    text-align: start;
  }
  .css-85irbq {
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 12px;
  }
  .css-1mwciyk {
    position: relative;
    inset: 8px 0px 0px;
  }
  .css-1e0bg48 {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-direction:row;
    min-height: 18px;
    min-width: 18px;
    position: relative;
  }
  .css-18q90e8 {
    position: relative;
    left: 19px;
      z-index: 1;
      bottom: 3px;
      width: 20px;
  }
  .css-1xrwcn5 {
    align-items: flex-start;
    color: rgb(30, 30, 30);
    display: flex;
    min-height: 18px;
    min-width: 18px;
    position: relative;
  }
  .css-1xrwcn5::before {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(134, 134, 134);
    border-radius: 2px;
    content: "";
    cursor: pointer;
    display: block;
    flex: 0 0 auto;
    height: 18px;
    transition-duration: 0.2s;
    transition-property: border;
    transition-timing-function: ease;
    width: 18px;
  }
  .css-1xrwcn5 > span::before {
    border-color: transparent;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    content: "";
    display: block;
    height: 18px;
    inset-inline-start: 0px;
    position: absolute;
    top: 0px;
    transition-duration: 0.2s;
    transition-property: border;
    transition-timing-function: ease;
    width: 18px;
  }

 
  
 
  
  .card-heading {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f4f7;
    padding: 5px 19px 5px 5px;
}

.top_reels {
    position: fixed;
    width: 100%;
    height: calc(90vh - 90px);
    overflow-x: scroll;
    overflow-y: scroll;
}

.css-1ixbps20l {
    pointer-events: none;
    font-size: 10px;
}


.week-sv232 {
  background-color: #ffffff;
  margin-bottom: 3px;
  justify-content: center;
}

.date_reals{
  background-color: #ffd8d5;
}
.shift_name{
  font-weight: 700;
    text-transform: capitalize;
}


.loader {
  position: relative;
  width:  48px;
  height: 48px;
  background: #022c49;
  transform: rotateX(65deg) rotate(45deg);
  color: #033e66;
  animation: layers1 1s linear infinite alternate;
}
.loader:after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(0, 17, 42, 0.7);
  animation: layerTr 1s linear infinite alternate;
}

@keyframes layers1 {
  0% { box-shadow: 0px 0px 0 0px  }
 90% , 100% { box-shadow: 20px 20px 0 -4px  }
}
@keyframes layerTr {
  0% { transform:  translate(0, 0) scale(1) }
  100% {  transform: translate(-25px, -25px) scale(1) }
}
    
      


.checkbox-row {
  display: flex;
  justify-content: space-around; /* Distribute evenly */
  text-align: center;
}
.checkbox-header {
  padding: 10px;
  flex: 1; /* Makes all items take equal width */
}

.checkbox-body {
  text-align: center;
  padding: 10px 0;
}

.checkbox-input {
  margin-top: 5px;
}



  .w-28p{
    width: 28px;
  }

  .color-input-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
  }
  
  .form-control-color-update {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer; /* Indicates interactivity */
    opacity: 0; /* Hide the default color input UI */
  }

  
  .form-control-color {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer; /* Indicates interactivity */
    opacity: 0; /* Hide the default color input UI */
  }
  
  .color-display {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #ccc; /* Optional border */
    box-shadow: 0 0 0 2px #ddd; /* Optional shadow */
  }
  

  .hovered:hover{
    background: #afd1f6;
    
  }

  .hovereds:hover{
   opacity: 1;
    
  }


    .css-1m92us_absence {
    -webkit-box-align: center;
    align-items: center;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-flex;
    font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: normal;
    margin: 0px;
    overflow: visible;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
    vertical-align: middle;
    background-color: #e4d03b;
    color: #667085;
    font-size: 11px;
    min-height: 30px;
    width: 96px;
    font-weight: 500;
    line-height: 0.5;
    right: 1px;
    }
    .css-1m92us_absence_sheet {
      -webkit-box-align: center;
      align-items: center;
      border-color: transparent;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      display: inline-flex;
      font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
      -webkit-box-pack: center;
      justify-content: center;
      letter-spacing: normal;
      margin: 0px;
      overflow: visible;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-transform: none;
      transition-property: background-color, border-color, box-shadow, color;
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
      vertical-align: middle;
      background-color: #e4d03b;
      color: #667085;
      font-size: 11px;
      min-height: 30px;
      width: 46px;
      font-weight: 500;
      line-height: 0.5;
      right: 1px;
      }
    .css-vk-absen:hover  .tooltip-text-absence  {
      opacity: 1;
    }

    .tooltip-text-absence {
      opacity: 0;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      padding: 8px;
      border-radius: 5px;
      font-size: 12px;
      white-space: nowrap;
      transition: opacity 0.3s;
      pointer-events: none;
      margin: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .tooltip-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-weight: bold;
    }
    
    .count-as {
      text-align: left;
      flex: 1;
    }
    
    .absence-status {
      text-align: right;
      flex: 1;
    }
    
    .absence-dates {
      margin-top: 5px;
      text-align: center;
      font-size: 11px;
    }
    

    .absence-summary-item {
      color: #333;
    }
    
    .flex {
      display: flex;
    }
    
    .items-start {
      align-items: flex-start;
    }
    
    .gap-3 {
      gap: 0.75rem;
    }
    
    .avatar-badge-group {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding-top: 0.25rem;
    }
    
    .badge-icon {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 0.375rem;
      background-color: white;
      border-color: #f2ca00;
    }
    
    .icon-container svg {
      fill: currentColor;
      height: 0.875rem;
      width: 0.875rem;
    }
    
    .avatar img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
    
    .summary-item {
      width: 100%;
    }
    
    .title-section {
      margin-top: 0.125rem;
    }
    
    .name {
      font-size: 1rem;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    
    .status-info {
      font-size: 0.875rem;
      gap: 0.5rem;
    }
    
    .date-info {
      font-size: 0.875rem;
      gap: 0.5rem;
    }
    
    .date-details {
      color: #666;
    }
    .status-list {
      color: #333;
    }
    
    .status-header {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    
    .icon {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
    }
    
    .warning-icon {
      color: #f2ca00;
    }
    
    .status-title {
      font-size: 1.125rem;
      font-weight: bold;
      color: #333;
      margin: 5px 0px 3px;
    }
    
    .status-item {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      color: #666;
    }
    
    .status-message {
      margin: 0;
      font-size: 0.875rem;
    }
    
    .text-warning-500 {
      color: #f2ca00;
    }
    
    .text-word-mark-800 {
      color: #333;
    }
    
  .text-grey-800 {
      --tw-text-opacity: 1 !important;
      color: #1d2939 !important;
  }

  .outline-none {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
}


.border-transparent {
  border-color: transparent !important;
}

.border-solid {
  border-style: solid !important;
}

.border {
  border-width: 1px !important;
}

.overflow-visible {
  overflow: visible !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.inline-flex {
  display: inline-flex !important;
}
.dropUl {
  position: absolute;
  display: inline-block;
  top: 40px;
  background-color: white;
  width: 92%;
  padding: 10px;
  list-style: none;
  font-weight: 500;
  text-align: justify;
}

.dropLi {
  padding: 5px;
  border-bottom: 1px solid #c9c9c9;
  cursor: pointer;
}

.dropLi:hover{
background-color: #daeafb;
}


.css-totals {
  position: fixed;
  bottom: 96px;
  right: 0;
  background: #e6e9fe;
  width: 135px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  border-bottom: 3px solid #85a085;
  height: 40px;
  z-index: 10;
}

.fixed-total-colum {
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  font-weight: 600;
  font-size: 12px;
  position: sticky;
  top: 0;
  right: 0;
  background: white;
}

.timesheet-period-employee-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
  color: #333;
}

.sh-totals {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: space-between; /* Adds space between time and salary */
  gap: 10px; /* Adds some spacing between the two elements */
}

.sh-totals__time, .sh-totals__salary {
  font-size: 11px;
  font-weight: bold;
}
.css-s3o0sad3-sheet {
  width: 58px;
  display: block;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

}

.date-schedule-table {
  display: inline-flex;
  flex-direction: column;
  width: auto;
  border-collapse: collapse;
}

.date-schedule-table th,
.date-schedule-table td {
  padding: 8px;
  border: 1px solid #ccc;
}

.empty-cell {
  text-align: center;
  color: #999;
}


.css-shord3-action{
  background-color: #04851e;
  color: #ffffff;
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  border-color: rgb(204, 204, 204);
  font-size: 12px;
  padding: 4px 14px;
  border-radius: 2px 0px 0px 2px;
  border-start-start-radius: 2px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border-end-start-radius: 2px;
  width: 128px;
  height:36px;
  }



 .form-modal__element{
    display: flex;
    flex-wrap: wrap;
}

.form-modal__label{
  font-weight: 600;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}







.form-modal__input{
  border-color: #eaecf0;
  border-radius: .25rem;
  height: 33px !important;
  display: inline-block;
  max-width: 100%;
  height: 36px;
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.25;
  color: #55595c;
  vertical-align: middle;
  background-size: 8px 10px;
  border: 1px solid #eaecf0;
  border-radius: .25rem;
}



.table-border {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color:#333,
}

.tr-tbl{
  background-color: #f2f4f7;
  color: #434343;
  border-bottom: 0;
  border-top: 1px solid #eaecf0;
}
.group-tbl{
  background-color: #fcfcfd;
  color: #333c4d;
  background: #f8f9f7;
  border-bottom: 2px solid #dcdcdc;
  margin-top: 10px;
  margin-bottom: 10px;
}
.group-tbl-w{
  margin-top: 10px;
  margin-bottom: 10px;
}
.table-border td:last-child {
  padding-right: 12px;
}

.table-border td:first-child {
  padding-left: 12px;
}
.table-rate-card__day{
  padding: 5px;
    font-size: 13px;
    font-weight: bold;
}

.w-28 {
  width: 7rem !important;
}

.inline-block {
  display: inline-block !important;
}

.items-stretch {
  align-items: stretch !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.sbinput{

  border-color: #eaecf0;
  border-radius: .25rem;
  height: 33px !important;
  display: inline-block;
  max-width: 100%;
  height: 36px;
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.25;
  color: #55595c;
  vertical-align: middle;
  background-size: 8px 10px;
  border: 1px solid #eaecf0;
  border-radius: .25rem;
}
.select_tbl{
  min-width: 107px;
  border-color: #eaecf0;
  border-radius: .25rem;
  height: 33px !important;
  display: inline-block;
  max-width: 100%;
  height: 36px;
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.25;
  color: #55595c;
  vertical-align: middle;
  background-size: 8px 10px;
  border: 1px solid #eaecf0;
  border-radius: .25rem;
}

.spinner-overlay-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent white background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure it’s above other content */
}

.spinner-modal {
  border: 5px solid #f3f3f3; /* Light gray */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Spinner Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text-total {
  visibility: hidden;
  width: 200px; /* Adjust width as needed */
  background-color: #333; /* Tooltip background */
  color: #fff; /* Tooltip text color */
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-text-total::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow below the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text-total {
  visibility: visible;
  opacity: 1;
}

.tooltip-item {
  display: flex;
  justify-content: space-between;
}

.tooltip-label {
  font-weight: bold;
  color: #ffffff;
}

.tooltip-value {
  color: #ffffff;
}