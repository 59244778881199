.css-editable-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .css-editable-table th,
  .css-editable-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .editable-input {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    font-size: 14px;
    outline: none;
  }
  .editable-select {
    border: 1px solid #ccc;
    padding: 2px;
    width: 100%;
    font-size: 14px;
    margin: 0px 4px 6px;
  }
  

  .editable-select:focus {
    border-color: #007bff;
    outline: none;
  }
  .editable-input:focus {
    border-bottom: 2px solid #007bff;
  }
  
  .table_date.active {
    color: green;
  }
  
  .table_date.inactive {
    color: red;
  }
  
  .css-remove-button {
    background-color: #f9f9f9;
    color: #1e1e1e;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .css-remove-button:hover {
    background-color: #f2f2f2;
  }
  .editable-container {
    display: inline-flex;
    align-items: center;
    position: relative;
  }
  
  .post-icon {
    border: none;
    background: none;
    color: #007bff;
    cursor: pointer;
    margin-left: 5px;
    font-size: 16px;
    padding: 0;
    display: none; /* Hidden by default */
  }
  
  .editable-container:focus-within .post-icon {
    display: inline; /* Display when any child input is focused */
  }
  
  .editable-container input:focus {
    border-bottom: 2px solid #007bff;
  }
  
  .post-icon:hover {
    color: #0056b3;
  }
  
  .mdi-send {
    font-size: 1.2rem;
    vertical-align: middle;
  }
  

  .css-1u2njnl_fleet tr:last-of-type th:nth-of-type(1) {
    width: 2px;
    text-align: start;
}

.css-1u2njnl_fleet {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    background-color: rgb(255, 255, 255);
    border-collapse: separate;
    border-spacing: 0px;
    border-top: 1px solid rgb(233, 233, 233);
    color: rgb(30, 30, 30);
    width: 100%;
  }

  .css-1u2njnl_fleet td:nth-of-type(1) {
    width: 2px;
    text-align: start;
  }
  .css-1u2njnl_fleet tr:last-of-type th:nth-of-type(3) {
    width: 20%;
    text-align: start;
  }
  .css-1u2njnl_fleet tr:last-of-type th:nth-of-type(4) {
    width: 20%;
    text-align: start;
  }

  .css-1u2njnl_fleet tr:last-of-type th:nth-of-type(5) {
    width: 20%;
    text-align: start;
  }
  .css-1u2njnl_fleet td:nth-of-type(4) {
    width: 20%;
    text-align: start;
  }
  .css-1u2njnl_fleet td {
    text-align: start;
  }

  .css-1u2njnl_fleet td:nth-of-type(5) {
    width: 25%;
    text-align: start;
  }

  .css-17bqg1m_fleet:first-of-type {
    border-inline-start: 3px solid transparent;
    padding-inline-start: 21px;
  }
  .css-17bqg1m_fleet {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 0px 12px;
    text-transform: inherit;
    line-height: 0 !important;
  }
  .css-17bqg1m_fleet:last-child {
    border-inline-end: none;
    padding-inline-end: 24px;
  }

  .css-3div8j_fleet {
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
  }

  .css-rhk3of_fleet {
    font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 8px 12px;
    text-transform: inherit;
  }

  .css-cir4u5_fleet {
    background-color: rgb(255, 255, 255);
    transition: background-color 0.2s ease 0s;
  }
  .css-cir4u5_fleet td:first-of-type {
    border-inline-start-color: rgb(36, 99, 209);
  }

  .css-85irbq_fleet:first-of-type {
    border-inline-start: 3px solid transparent;
    padding-inline-start: 21px;
  }

  .css-85irbq_fleet {
    border-bottom: 1px solid rgb(233, 233, 233);
    padding: 10px;
    font-size: 11px;
  }

  .css-85irbq_fleet:last-child {
    border-inline-end: none;
    padding-inline-end: 24px;
  }

  .css-1mwciyk_fleet {
    position: relative;
    inset: 8px 0px 0px;
    padding: 5px;
  }


  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
  }
  
  .image-container {
    overflow: hidden;
    position: relative;
  }
  
  .image-container img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image-container img:hover {
    transform: scale(1.1);
  }
  .doc-upload-container {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }
  
  .doc-type-select {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .doc-file-input {
    font-size: 14px;
  }
  
  .upload-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-btn:hover {
    background-color: #0056b3;
  }
    
  .edit-group{
    justify-content: space-around;
  }