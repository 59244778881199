@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300");
// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

@font-face {
  font-family: 'LovlyTropical';
  src: url('../fonts/lovelytropical.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BitterSignature';
  src: url('../fonts/BitterSignature.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Histopher';
  src: url('../fonts/Histopher.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SignaturemomentRegular';
  src: url('../fonts/SignaturemomentRegular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BarokahSignatureRegular';
  src: url('../fonts/BarokahSignatureRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BugisniaPersonal';
  src: url('../fonts/BugisniaPersonal.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AlistaBlairRegular';
  src: url('../fonts/AlistaBlairRegular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Rossa';
  src: url('../fonts/Rossa.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DeniraSignature';
  src: url('../fonts/DeniraSignature.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@media (max-width: 768px) {
  .brand-name {
    font-size: 1rem; /* Smaller font size on small screens */
    margin-left: 5px; /* Adjust spacing on small screens */
  }
}
.frist_sign{
font-family: LovlyTropical;
}
.sceond_sign{
  font-family: BitterSignature;
}
.three_sign{
  font-family: Histopher;
}
.four_sign{
  font-family: SignaturemomentRegular;
}
.five_sign{
  font-family: BarokahSignatureRegular;
  font-size: 13px;
}
.six_sign{
  font-family: BugisniaPersonal;
}
.seven_sign{
  font-family: AlistaBlairRegular;
}
.eight_sign{
  font-family: Rossa;
}
.night_sign{
  font-family: DeniraSignature;
}
body {
  * {
    outline: none;
    font-family:sans-serif;
  }
}
.pay-noti{
  background: #556ee6;
}
.bg-img{
  background-image: url('../images/banner.png');
  background-repeat: round;
}

.css-zm3qx2 {
  color: rgb(255, 255, 255);
}
.hover-state-gray {
  position: relative;
  background: rgba(255,255,255,0);
  border-radius: 3px;
  padding: 12px 12px 12px;
}
.hover-state-gray:hover {
  position: relative;
  background: rgba(235, 242, 250, 0.631);
  border-radius: 3px;
  padding: 12px 12px 12px;
 cursor: pointer;
}
.css-cmcycf {
  background: none;
  border: none;
  font-size: 11px;
  font-weight: bold;
  line-height: 11px;
  min-width: 140px;
  padding-inline-start: 25px;
  position: relative;
  text-align: start;
  color: rgb(0, 0, 0);
}
.css-cmcycf::before {
  border-bottom: 2px solid rgb(0, 92, 185);
  border-inline-start: 2px solid rgb(0, 92, 185);
  border-start-start-radius: 5px;
  border-end-start-radius: 5px;
  border-top: 2px solid rgb(0, 92, 185);
  content: "";
  display: block;
  height: 50px;
  inset-inline-start: 0px;
  position: absolute;
  top: 4px;
  width: 20px;
}
.css-cmcycf::before {
  border-color: rgb(0, 0, 0);
}
.css-fv3lde {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}
.css-sjq1pw {
  display: block;
  height: 52px;
  margin-block: -8px;
  margin-inline: -20px 0px;
  /* filter: brightness(0) invert(100%); */
  mix-blend-mode: normal;
  max-width: 100%;
  border: 0;
}
.css-lebles_id3 {
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
  margin-block: 5px;
  margin-inline: -8px 0px;
  min-height: 26px;
}
.css-1j983t3 {
  display: block;
  max-width: 114px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grid_pay {
  display: grid;
  grid-gap: 1em;
  margin: 0;
  padding: 0;
}
.card_pay {
  background: #fff;
  border: 1px solid #e2ebf6;
  border-radius: 0.25em;
  cursor: pointer;
  display: flex;
  padding: 1em;
  position: relative;
  transition: all 0.2s;
}
.card_pay:hover {
  border-color: #c4d1e1;
  box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.15);
  transform: translate(-4px, -4px);
}

.card__image_pay {
  border-radius: 0.25em;
  height: 6em;
  min-width: 6em;
}

.card__content_pay {
  flex: auto;
  padding: 0 1em;
}
.flex {
  display: flex!important;
}
.card_pay h2 {
  font-weight: 700;
  margin: 0;
}

.card_pay p {
  color: #546e7a;
  margin: 0;
}
.check_Driver_deatails{
  color: #1d8f00;
}
/* Checkbox Styles */
.checkbox_pay {
  -webkit-appearance: value;
  -moz-appearance: value;
  appearance: value;
  cursor: pointer;
  background: #e2ebf6;
  border-radius: 50%;
  height: 2em;
  margin: 0;
  margin-left: auto;
  flex: none;
  outline: none;
  position: relative;
  transition: all 0.2s;
  width: 2em;
  top: 25px;
}
.checkbox_pay:after {
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  content: "";
  display: block;
  height: 1em;
  left: 0.625em;
  position: absolute;
  top: 0.25em;
  transform: rotate(45deg);
  width: 0.5em;
}
.checkbox_pay:focus {
  box-shadow: 0 0 0 2px rgba(100, 193, 117, 0.6);
}
.checkbox_pay:checked {
  background: #64c175;
  border-color: #64c175;
}

.checkbox-control__target_pay {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* SVG Styles */
.draw-signature-wrapper {
  border: 1px dashed #cdcdcd;
  margin: 0px 0px 0 15px;
}
.signature-panel-label {
  border-top: 1px solid #ccc;
  color: #999;
  margin: -36px  8px 10px;
  padding-top: 16px;
  text-align: center;
  /* position: relative; */
  z-index: -1;
}
.signature-panel-sign-here {
  color: #ccc;
  left: 10px;
  position: absolute;
  bottom: 45px;
}



.signature-pad  {
  font-weight: bold;
  cursor: crosshair;
}
.praaf-pad{
  font-weight: bold;
  cursor: crosshair;
}
.signature-panel-clear {
  float: right;
  color: blue;
}
span.drop_lebel {
  position: absolute;
  top: 70px;
  width: 100%;
  bottom: 0;
  font-size: 14px;
}
span.drop_lebel_praaf {
  position: absolute;
  top: 70px;
  width: 100%;
  bottom: 0;
  font-size: 14px;
  left: 0;
}
.border-dot {
  border: 1px dashed;
  text-align: center;
  padding: 1rem;
}
.bg-hover:hover{
  background: #f6f7f9;
}
.bg-hover-2:hover{
  background: #dfeaff;
}

#file-drop-container {
  border: 2px dashed black;
  padding: 20px;
  font-size: 20px;
}

#file-drop-container.highlight {
  border-color: blue;
}
.accordion-header_title{
  font-size: 1.5rem;
  font-weight: 700;
}
.css-10ib5jr {
  margin-bottom: 40px;
}
.css-z1d1ba {
  display: flex;
  flex-wrap: wrap;
  margin: -24px 0px 0px -24px;
}
.css-130w8nn {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  display: inline-block;
  vertical-align: top;
  width: 240px;
}



.css-130w8nn {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 217, 217);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    display: inline-block;
    vertical-align: top;
    width: 240px;
}

.css-z1d1ba > * {
    max-width: calc(100% - 24px);
    margin: 24px 0px 0px 24px;
    flex-basis: auto;
    flex-shrink: 0;
}
.css-90hogc {
  border: 0px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.css-18lurlg {
  padding: 24px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}
.css-f2opis {
  text-align: center;
  height: 55px;
  margin-bottom: 22px !important;
}
.css-1bl3roz {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(36, 99, 209);
  color: rgb(255, 255, 255);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 4px 14px;
  width: 100%;
}
.css-1cr5iyr {
  border-radius: 2px 2px 0px 0px;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.css-1cr5iyr::before {
  content: "";
  display: block;
  padding-top: 75%;
  width: 100%;
}
.css-8dpnl8 {
  -webkit-box-align: center;
  align-items: center;
  bottom: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  inset-inline: 0px;
  position: absolute;
  top: 0px;
  z-index: 100;
  transition: background 0.1s ease 0s;
}
.css-4hfwli {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 4px 14px;
  background-color: transparent;
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}
.css-8dpnl8 button, .css-8dpnl8 a {
  opacity: 0;
  transition: opacity 0.1s ease 0s, background 0.2s ease 0s;
}
.css-1ixbp0l {
  pointer-events: none;
}
.css-1pdgtej {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.css-4fnwh7 {
  display: inline-flex;
  margin: 4px;
  position: absolute;
  z-index: 5;
  inset-inline-end: 0px;
  top: 0px;
}
.css-qfdd9t {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.css-n0qoxq {
  bottom: 0px;
  inset-inline: 0px;
  position: absolute;
  top: 0px;
}
.css-1r50d7z {
  border: 0px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
.css-1cr5iyr img {
  border-radius: 1.5px 1.5px 0px 0px;
  bottom: 0px;
  inset-inline: 0px;
  margin: auto;
  position: absolute;
  top: 0px;
  width: 300px;
}
.css-6t6lyd + .css-6t6lyd {
  border-top: 1px solid rgb(233, 233, 233);
}
.css-2t5fla {
  align-items: flex-start;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 12px 16px;
  position: relative;
}
.css-2t5fla > :nth-of-type(1) {
  flex: 1 1 0%;
  min-width: 0px;
}
.css-36j3xk {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.css-rxwkhh {
  margin: 0px;
  padding: 0px;
  color: rgb(102, 102, 102);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.css-2t5fla > :nth-of-type(2) {
  flex-shrink: 0;
  position: relative;
}
.css-y1ho7e {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  color: rgb(30, 30, 30);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 0px;
}
.css-2t5fla > :nth-of-type(2) > button {
  margin-inline-end: -12px;
  padding: 0px 5px;
}
.css-1vwl5y3 {
  line-height: 0.66px;
  pointer-events: none;
  margin-inline: -4px 6px;
  margin: 0px;
}

.css-y1ho7e svg {
  fill: rgb(134, 134, 134);
  transition: fill 0.2s ease 0s;
}

.css-1pdgtej {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
#displayNone{
display: none;
}
#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}

svg > rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #dee2e6;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

// kanban board
.react-kanban-board {
  display: inherit !important;

  .react-kanban-column {
    background-color: #ffffff;
    padding: 1.25rem;

    [data-rbd-draggable-context-id] {
      > div {
        display: block !important;
      }
    }
  }

  > div {
    display: flex;
    justify-content: center;
    gap: 25px;

    @media (max-width: 991.98px) {
      flex-direction: column;

      .react-kanban-column {
        width: 100%;
      }
    }
  }
}

.react-kanban-column {
  width: 33.33%;
}

.react-kanban-column > div {
  display: block !important;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: #ffffff !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid #dee2e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
// .file-manager {
//   .table td {
//     padding: 0.35rem;
//   }
// }

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

//react-table
.custom-header-css {
  thead {
    tr {
      th {
        div:last-child {
          min-height: 38px;
        }
      }
    }

    td:nth-last-child(2) {
      width: 150px !important;
    }
  }
}

.editor_email .ck-content {
  height: 200px;
}

// .my-masonry-grid {
//   display: -webkit-box;
//   /* Not needed if autoprefixing */
//   display: -ms-flexbox;
//   /* Not needed if autoprefixing */
//   display: flex;
//   margin-left: -25px;
//   /* gutter size offset */
//   width: auto;
// }

// .my-masonry-grid_column {
//   padding-left: 25px;
//   /* gutter size */
//   background-clip: padding-box;
// }

// /* Style your items */
// .my-masonry-grid_column>div {
//   /* change div to reference your elements you put in <Masonry> */
//   background: transparent;
//   margin-bottom: 20px;
// }

.jobs-categories .card-body a:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 991.98px) {
  .search-box {
    display: block !important;
    .search-label {
      float: none;
      width: 100%;
      .form-control {
        border-radius: 0.25rem !important;
      }
    }
  }
}

// select

.css-1s2u09g-control, .css-1pahdxg-control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.css-14el2xx-placeholder {
  color: $input-placeholder-color !important;
}

.css-26l3qy-menu {
  box-shadow: none !important;
  border: 1px solid $input-border-color !important;
}

.css-4ljt47-MenuList {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 4px !important;
}

.css-319lph-ValueContainer .css-qc6sy-singleValue {
  color: $input-color !important;
}
.select2-selection .css-b62m3t-container {
  color: $input-color !important;
}
.css-1n7v3ny-option, .css-9gakcf-option {
  background-color: $primary !important;
  color: $white !important;
}


// rdw-editor

.rdw-editor-toolbar,
.rdw-option-wrapper,
.rdw-editor-main {
  border-color: $input-border-color !important;
  background-color: var(--#{$prefix}custom-white) !important;
}

.rdw-option-wrapper {
  border: none !important;
  box-shadow: none !important;
}

.rdw-dropdown-wrapper {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
  box-shadow: none !important;
}

.rdw-dropdown-selectedtext {
  color: var(--#{$prefix}body-color) !important;
}

[data-layout-mode="dark"] {
  .rdw-option-wrapper,
  .rdw-dropdown-selectedtext {
    img {
      filter: invert(1) !important;
    }
  }
}

// ck editor

.ck.ck-toolbar {
  background-color: var(--#{$prefix}light) !important;
  border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
  height: 300px !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: $input-border-color !important;
}

.selectall.checkbox-wrapper-mail label:before{
  margin-top: 0;
}

// scroll
.ps__rail-y, .ps__rail-x{
  z-index: 999 !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    
    background-color: #fff !important;
    color: #000000  !important;
    -webkit-text-fill-color: var(--#{$prefix}body-color) !important;
} 



// Select2

.select2-selection__control, .select__control, .select2-selection .css-13cymwt-control, .css-t3ipsp-control{
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.select2-selection__menu, .select__menu, .css-1nmdiq5-menu{
  // background-color: var(--#{$prefix}card-bg) !important;
  box-shadow: 0 0 0 1px var(--#{$prefix}border-color) , 0 4px 11px var(--#{$prefix}border-color)  !important;
}

.select2-selection__option--is-focused, .select__option--is-focused,  .css-d7l1ni-option{
  background-color: $primary !important;
  color: $white !important;
}

.select__multi-value, .select2-selection__multi-value, .css-1p3m7a8-multiValue{
  background-color: var(--#{$prefix}light) !important;
}

.select__multi-value__label, .select2-selection__multi-value__label, .css-qbdosj-Input, .css-1dimb5e-singleValue, .css-wsp0cs-MultiValueGeneric{
  color: var(--#{$prefix}body-color) !important;
}

.select__multi-value__remove, .select2-selection__multi-value__remove{
  background-color: var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

// auth-logo

[data-layout-mode="dark"]{
  .auth-logo{
    .auth-logo-light{
      display: block;
    }
    .auth-logo-dark{
      display: none;
    }
  }
}


.startNowcard{
  border: 3px dashed #00b9f9;
  cursor: pointer;
}
.startNow{
  background: #012858;
  color: aliceblue;
  padding: 20px 0px 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.startNowbtn{
  background: #ffc626;
  border: none;
  color: #000000;
  font-size: 16px;
  border-radius:unset;
  padding: 10px 30px 10px 30px;
}
.startNowbtn:hover{
  background: #fed461;
  border: none;
  color: #000000;
  font-size: 16px;
  border-radius:unset;
  padding: 10px 30px 10px 30px;
}
#preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.sign-onlinetext {
  text-align: center;
}
.backpage{
  color: #585c5b;
}
.section-title{
  border-bottom:"1px solid #ccc";
  box-shadow: "0 2px 5px rgba(0, 0, 0, 0.1)";
  color: "#333";
}
.section-title h2 {
  font-size: 26px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  color: #000000;
}
.section-title h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 7px;
  width: 228px;
  background-color: #111;
}
.section-title h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 100%;
  background-color: #333;
}

  .css-10ib5jr {
    margin-bottom: 40px;
  }
  .css-z1d1ba {
    display: flex;
    flex-wrap: wrap;
    margin: -24px 0px 0px -24px;
  }
  .css-130w8nn {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 217, 217);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    display: inline-block;
    vertical-align: top;
    width: 240px;
  }
  
  
  
  .css-130w8nn {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(217, 217, 217);
      border-radius: 3px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      display: inline-block;
      vertical-align: top;
      width: 240px;
  }
  
  .css-z1d1ba > * {
      max-width: calc(100% - 24px);
      margin: 24px 0px 0px 24px;
      flex-basis: auto;
      flex-shrink: 0;
  }
  .css-90hogc {
    border: 0px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .css-18lurlg {
    padding: 24px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .css-f2opis {
    text-align: center;
    height: 55px;
    margin-bottom: 22px !important;
  }
  .css-1bl3roz {
    font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 18px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 2px;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.66px;
    margin: 0px;
    overflow: visible;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
    vertical-align: middle;
    background-color: rgb(36, 99, 209);
    color: rgb(255, 255, 255);
    font-size: 12px;
    min-height: 28px;
    min-width: 28px;
    padding: 4px 14px;
    width: 100%;
  }
  .css-1cr5iyr {
    border-radius: 2px 2px 0px 0px;
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  .css-1cr5iyr::before {
    content: "";
    display: block;
    padding-top: 75%;
    width: 100%;
  }
  .css-8dpnl8 {
    -webkit-box-align: center;
    align-items: center;
    bottom: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    inset-inline: 0px;
    position: absolute;
    top: 0px;
    z-index: 100;
    transition: background 0.1s ease 0s;
  }
  .css-4hfwli {
    font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 18px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.66px;
    margin: 0px;
    overflow: visible;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
    vertical-align: middle;
    font-size: 12px;
    min-height: 28px;
    min-width: 28px;
    padding: 4px 14px;
    background-color: transparent;
    border-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
  }
  .css-8dpnl8 button, .css-8dpnl8 a {
    opacity: 0;
    transition: opacity 0.1s ease 0s, background 0.2s ease 0s;
  }

  .css-1pdgtej {
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .css-4fnwh7 {
    display: inline-flex;
    margin: 4px;
    position: absolute;
    z-index: 5;
    inset-inline-end: 0px;
    top: 0px;
  }
  .css-qfdd9t {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .css-n0qoxq {
    bottom: 0px;
    inset-inline: 0px;
    position: absolute;
    top: 0px;
  }
  .css-1r50d7z {
    border: 0px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }
  .css-1cr5iyr img {
    border-radius: 1.5px 1.5px 0px 0px;
    bottom: 0px;
    inset-inline: 0px;
    margin: auto;
    position: absolute;
    top: 0px;
  }
  .css-6t6lyd + .css-6t6lyd {
    border-top: 1px solid rgb(233, 233, 233);
  }
  .css-2t5fla {
    align-items: flex-start;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 12px 16px;
    position: relative;
  }
  .css-2t5fla > :nth-of-type(1) {
    flex: 1 1 0%;
    min-width: 0px;
  }
  .css-1u2njnl tr:last-of-type th:nth-of-type(1) {
    width: 2px;
    text-align: start;
}
  .css-36j3xk {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .css-rxwkhh {
    margin: 0px;
    padding: 0px;
    color: rgb(102, 102, 102);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .css-2t5fla > :nth-of-type(2) {
    flex-shrink: 0;
    position: relative;
  }
  
  .css-y1ho7e {
    font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 18px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 2px;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.66px;
    margin: 0px;
    overflow: visible;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
    vertical-align: middle;
    background-color: transparent;
    color: rgb(30, 30, 30);
    font-size: 12px;
    min-height: 28px;
    min-width: 28px;
    padding: 0px;
  }
  .css-2t5fla > :nth-of-type(2) > button {
    margin-inline-end: -12px;
    padding: 0px 5px;
  }
  .css-1vwl5y3 {
    line-height: 0.66px;
    pointer-events: none;
    margin-inline: -4px 6px;
    margin: 0px;
  }

  .css-y1ho7e svg {
    fill: rgb(134, 134, 134);
    transition: fill 0.2s ease 0s;
  }

  .css-1pdgtej {
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .buttons-container {
    margin-bottom: 10px; /* Adjust the value as needed */
  }
  
  .newpad{
    margin: 10px;
}
.download-btn{
  position: absolute;
    right: 40px;
    bottom: 0px;
    top: 0px;
}
.message-list li .col-mail-2 {
  position: absolute;
  top: 0;
  left: 244px;
  right: 0;
  bottom: 0;
}
.message-list li .col-mail-2 .subject {
  left: 0;
  right: 388px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.message-list li .col-mail-1 .title {
  position: absolute;
  top: 0;
  left: 82px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
}
.message-list li .col-mail-2 .date {
  right: 146px;
  top: 0;
  width: 170px;
  padding-left: 80px;
}
.message-list li .col-mail-1  .status {
  position: absolute;
  top: 20px; /* Adjust the value based on your needs */
  left: 110px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
}

.startbtn{
  line-height: 24px;
}
.startbtn:hover{
  line-height: 24px;
  background: #012858;
  color: #ffffff;
}
.linkbtn{
  color: #ffffff;
}
.css-16cz7xa{
  border-bottom: 1px solid rgb(204, 204, 204);
    display: flex;
    flex-direction: row;
    min-height: 41px;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    flex: 0 0 auto;
    position: relative;
    background-color: rgb(233, 233, 233);
}
.css-ml9n4n {
  max-width: 375px;
  min-width: 220px;
  padding-left: 12px;
  width: 240px;
  display: inline-block;
}
.css-pre3sf{
  width: 100%;
}
.css-13dzsk6 {
  border: 0px;
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  position: absolute;
}
.css-1jbj2f5 {
  background-color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  padding-left: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 20px;
  width: 100%;
  min-height: 28px;
  font-size: 13px;
  border: 1px solid rgb(204, 204, 204);
}
.css-1342dz3ssk6{
  position: relative;
  padding-left: 15px;
  height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 100px;
  display: inline-block;
  margin-right: 8px;
}
.custom-select {
  position: relative;
}

.custom-select::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  height: 10px;
  width: 10px;
  min-width: 10px;
  background-color: rgb(255, 214, 91);
  border: 1px solid rgb(245, 196, 49);
  border-radius: 50%;
}
.css-1nsshgk {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.css-64bboa {
  display: inline-flex;
  vertical-align: middle;
}
.css-10w30g4 {
  line-height: 16px;
  background: transparent;
  border: 0px;
  display: inline-block;
  margin: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgb(169, 169, 169);
}



.css-guny5b {
  border: 0px;
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  position: absolute;
}
.css-1apspd7 {
  display: inline-flex;
  vertical-align: middle;
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid rgb(204, 204, 204);
}
.css-k008qs {
  display: flex;
}
.css-ud5b9z button {
  background: transparent;
  border: none;
  color: #3d3d3d;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
}
.css-zspncp {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  background-color: rgb(255, 255, 255);
  border-color: rgb(134, 134, 134);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  color: rgb(30, 30, 30);
  cursor: pointer;
  margin: 0px;
  min-height: 28px;
  overflow: visible;
  padding-block: 4px;
  padding-inline: 8px 24px;
  position: relative;
  text-align: start;
  text-decoration: none;
  transition: background-color 0.2s ease 0s, border 0.2s ease 0s, box-shadow 0.2s ease 0s, color 0.2s ease 0s;
  vertical-align: middle;
  width: 100%;
}
.css-y93q9o {
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  margin-inline-start: -22px;
  pointer-events: none;
  z-index: 1;
}
.css-lzaifp {
  display: inline-flex;
  pointer-events: none;
}

.css-1m1df6c {
  background-color: rgb(36, 99, 209);
  color: rgb(255, 255, 255);
  line-height: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  border: rgb(36, 99, 209);
}
.css-1ha7cte {
  line-height: 16px;
  background: transparent;
  border: 0px;
  display: inline-block;
  margin: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgb(30, 30, 30);
}
.create-signature {
  /* Set a default height or other styles */
  height: 100vh;
  /* Add any other necessary styles */

  /* Media query for smaller screens */
  @media screen and (max-width: 768px) {
    height: 80vh; /* Adjust the height for smaller screens */
  }
}
.css-1k8emhg {
  position: relative;
  width: 100vw;
  overflow: hidden;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: 75vh;

}
.css-1f26bup {
  background-color: rgb(233, 233, 233);
  border-right: 1px solid rgb(238, 238, 238);
}
.css-1t11ffy {

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  background: rgb(233, 233, 233);
  border-right: 1px solid rgb(204, 204, 204);
  position: relative;
  height: 100%;
  overflow: hidden;

}
.css-1twamlw {
  z-index: 1;
  bottom: 0px;
  cursor: col-resize;
  position: absolute;
  top: 0px;
  padding-left: 4px;
  padding-right: 4px;
  background: #e1e1e1;
}
.css-quz66i {
  -webkit-box-flex: 1;
  flex-grow: 1;
  position: relative;
  overflow: scroll;
  background-color: rgb(233, 233, 233);
  text-align: center;

}
.css-1szwono {
  flex-grow: 1;
    position: relative;
    overflow-y: auto;
    background-color: #e9e9e9;
    text-align: center;
}
.css-1s9vx5s {
  display: flex;
  flex-direction: column;
  background: rgb(249, 249, 249);
  overflow: hidden;
  width: 100%;
  border-top: 1px solid rgb(217, 217, 217);
  flex: 1 0 0%;
}
.css-1b1eheb {
  flex: 1 1 0%;
  overflow-y: auto;
  white-space: normal;
  display: flex;
  flex-direction: row;
  height: 100%;
}
.css-8mm5ho {
  background: rgb(249, 249, 249);
  overflow: auto scroll;
  width: 100%;
}
.css-fon8ac {
  -webkit-box-align: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 12px 15px 18px;
  padding: 0px;
}
.css-dr8fc {
  margin: 0px;
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}
.css-rdk5cf {
  background: none;
  border: none;
  border-radius: 0px;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: normal;
  line-height: inherit;
  padding: 0px;
  margin: 0px;
  text-align: inherit;
  text-decoration: none;
}
.css-19u637c:not(:last-child) {
  border-bottom: 1px solid rgb(217, 217, 217);
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.css-19u637c {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.css-21tq8i {
  margin-left: -15px;
  margin-right: -15px;
}
.css-q7sdae {
  margin: 8px 0px;
  padding: 0px;
}
.css-h0trhu {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  font-size: 13px;
  line-height: 18px;
  transition: background-color 0.2s ease 0s;
  user-select: none;
}
.css-zuu3pb {
  -webkit-box-align: center;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0px;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0px;
  overflow: hidden;
  padding-block: 5px;
  padding-inline: 15px 0px;
  position: relative;
  text-align: start;
  text-decoration: none;
  text-transform: inherit;
  width: 100%;
}
.css-xfo8x5 {
  border-radius: 3px;
  max-height: 22px;
  margin-inline-end: 8px;
  padding: 3px;
  background-color: rgb(255, 214, 91);
  color: rgb(102, 102, 102);
}
.css-xfo8x5Client {
  border-radius: 3px;
  max-height: 22px;
  margin-inline-end: 8px;
  padding: 3px;
  background-color: rgb(94, 255, 91);
  color: rgb(102, 102, 102);
}


.css-1sdoc{
  flex-direction: row;
  justify-content: center;
}
.css-1sdocth6{
  flex-grow: 1;
}
.zoom-wrapper {
  position: relative;
  display: inline-block;
}

.zoom-button {
  padding: 8px 16px;
  background-color: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.zoom-button.active {
  background-color: #e0e0e0;
}

.zoom-menu {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  list-style: none;
  z-index: 1;
}

.zoom-menu-item {
  padding: 8px;
  cursor: pointer;
}

.zoom-menu-item.selected {
  background-color: #e0e0e0;
}
.pdf-container {
  height: auto;
}
button.page-button {
  z-index: -2;
}
.resizable-new {
  // background:#ffd65bfc;
  // width: 120px;
  // height: 100px;
  position: absolute;
  // top: 100px;
  // left: 100px;
  cursor: move;
}
.objectelement:after {
  content: "";
  position:absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color:#03010100;
}
.resizable-new:after {
  content: "";
  position:absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color:#03010100;
}
.resizable-new .resizers{
  width: 100%;
  height: 100%;
  border: 3px solid #4286f4;
  box-sizing: border-box;
}
// .resizable-new .resizers::before {
//   content: "\f063";
//   font-family: "Font Awesome 5 Free";
//   font-weight: 900;
//   font-size: 24px;
//   position: absolute;
//   top: 34px;
//   left: 45px;
// }
.resizable-new .resizers .resizer{
  width: 10px;
  height: 10px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;
  border: 3px solid #4286f4;
  position: absolute;
  cursor: se-resize;
}

.resizable-new .resizers .resizer.top-left {
  left: -12px;
  top: -14px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.resizable-new .resizers .resizer.top-left::before {
  content: "+";
  position: absolute;
  top: -9px;
  right: 0;
  width: 100%;
  height: 100%;
  font-size: 27px;
  color: #000000;
}
.resizable-new .resizers .resizer.top-right {
  right: -12px;
  top: -16px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.resizable-new .resizers .resizer.top-right::before {
  content: "×";
  position: absolute;
  top: -9px;
  right: 0;
  width: 100%;
  height: 100%;
  font-size: 27px;
  color: #000000;
}
.resizable-new .resizers .resizer.bottom-left {
  left: -5px;
  bottom: -5px;
  cursor: nesw-resize;
}
.resizable-new .resizers .resizer.bottom-right {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;
}


.pageIndexID::after{
content: "";
}
.pageIndexID{
  background: #2463d1;
  transition: .5s ease;
  text-align: center;
  opacity: 1;
  color: white;
}
.modals_footers {
  position: fixed;
  display: block;
  bottom: 79px;
  background: white;
  flex-shrink: 0;
  align-items: center;
  margin: -50px;
  height: 64px;
  width:57% ;
  padding: 0px;
  justify-content: space-between;
  border-top: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
@media (min-width: 480px) and (max-width: 720px) {
  .modals_footers{
    bottom: 61px;
  }
}
.my-sub-btn{
  padding: 1rem;
}
.btn-sub{
  float: right;
}
.css-1e0bg48 {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  min-height: 18px;
  min-width: 18px;
  position: relative;
}
.css-1xrwcn5 {
  align-items: flex-start;
  color: rgb(30, 30, 30);
  display: flex;
  min-height: 18px;
  min-width: 18px;
  position: relative;
}
.css-1443df4{
  margin-top: 8px;
}
.css-1xrwcn7 {
  align-items: flex-start;
  color: rgb(30, 30, 30);
  display: flex;
  min-height: 18px;
  min-width: 18px;
  position: relative;
}
input:checked +  .css-1xrwcn5::before {
  background-color: rgb(255, 255, 255);
  border-color: rgb(134, 134, 134);
}

.css-1xrwcn7 > span::before {
  content: "";
  border-color: #616060;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: block;
  height: 18px;
  inset-inline-start: 0px;
  position: absolute;
  top: 0px;
  transition-duration: 0.2s;
  transition-property: border;
  transition-timing-function: ease;
  width: 18px;
}
.css-chekcsa32332 {
  border-color: #616060;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: block;
  height: 13px;
  inset-inline-start: 0px;
  position: absolute;
  top: 0px;
  transition-duration: 0.2s;
  transition-property: border;
  transition-timing-function: ease;
  width: 13px;
}


.css-1ujxqwa {
  align-items: flex-start;
  color: rgb(30, 30, 30);
  display: flex;
  min-height: 18px;
  min-width: 18px;
  position: relative;
}
.css-1xrwcn5::before{
  background-color: rgb(255, 255, 255);
  border-color: #868686;
}
.css-1cbszci {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  min-height: 18px;
  padding-inline-start: 29px;
}
.css-ss15sdw5 {
  clip-path: inset(7%);
  height: 16px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 16px;
  bottom: 9px;
  left: 1px;
}

.css-check3sdw{
  position: absolute;
    display: block;
    left: 0px;
    top: -3px;
    height: 18px;
    width: 17px;
}



.css-1rp7g54 {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  color: rgb(30, 30, 30);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 0px;
}
.css-t01nit {
  background: transparent;
  border: none;
  color: rgb(134, 134, 134);
  cursor: pointer;
  display: flex;
  padding: 8px 15px;
}

.css-1rpan28 {
  line-height: 0.66px;
  pointer-events: none;
  margin-inline: -4px 6px;
  margin: 0px;
}


.css-1pdgtej {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.accordion{
  --bs-accordion-btn-padding-y: 0.5rem;
}
.css-9f4jv1 {
  display: flex;
  flex-wrap: wrap;
}
.css-5gs0ys {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  overflow-wrap: break-word;
  color: rgb(30, 30, 30);
  display: block;
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.css-q10huv {
  background: rgb(255, 255, 255);
  display: flex;
  margin-bottom: 10px;
}
.css-hboir5 {
  display: flex;
  width: 100%;
}
.css-12ihcxq {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  appearance: none;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(134, 134, 134);
  color: rgb(30, 30, 30);
  border-radius: 2px;
  height: 28px;
  padding-block: 0px;
  padding-inline: 8px 24px;
  width: 100%;
  text-overflow: ellipsis;
}
.css-1baizyq {
  width: 24px;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-inline-start: -24px;
  pointer-events: none;
  text-overflow: ellipsis;
}
.css-lzaifp {
  display: inline-flex;
  pointer-events: none;
}
.css-199kmjd {
  display: inline-flex;
}
.css-vxcmzt {
  display: inline-flex;
}
.css-1pdgtej {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.css-4lt7ka {
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  border: 0px;
  border-radius: 2px;
  color: rgb(102, 102, 102);
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 28px;
  min-width: 28px;
  padding: 4px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.2s;
  transition-property: background-color;
  transition-timing-function: ease;
  margin-left: 2px;
  cursor: pointer;
}
.css-129ohl {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(249, 249, 249);
  border-color: rgb(204, 204, 204);
  color: rgb(30, 30, 30);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 4px 14px;
  width: 100%;
}
.css-16hz1ch {
  pointer-events: none;
}

.css-qmb2mn {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  color: rgb(30, 30, 30);
  font-size: 11px;
  min-height: 22px;
  min-width: 22px;
  padding: 1px 10px;
}
.css-1pig3b7 {
  line-height: 0.66px;
  pointer-events: none;
  margin-inline: -4px 6px;
}
.css-16hz1ch {
  pointer-events: none;
}
.css-1mc4uv4 {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.css-ww6f74 {
  border-inline-end: none;
  padding-inline-end: 24px;
  border-bottom: 1px solid rgb(233, 233, 233);
  padding: 6px 12px;
}
.css-b1ilmt {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  color: rgb(30, 30, 30);
  font-size: 11px;
  min-height: 22px;
  min-width: 22px;
  padding: 0px;
}
.css-1rpan28 {
  line-height: 0.66px;
  pointer-events: none;
  margin-inline: -4px 6px;
  margin: 0px;
}

.css-1pdgtej {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.css-zf56fy {
  display: inline-flex;
  line-height: 0;
}
.css-4lt7ka span {
  display: inline-flex;
}
.css-16htk9o {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  padding-inline: 4px;
}

.css-4lt7ka svg {
  fill: currentcolor;
}
.css-zf56fy svg {
  height: 14px;
  width: 14px;
}
.css-vlt30q {
  padding: 13px 24px;
  border-bottom: 1px solid rgb(217, 217, 217);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.custom-cursor {
  width: 30px;
  height: 30px;
  border: 2px solid black;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.css-12iss3cds{
  width: 100%;
  margin: 5px;
}
.ink-secondary-button {
  display: inline-flex;
  padding: 4px; 
  margin: 5px;
}
.css-1m5o92u {
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(0, 105, 236);
  color: rgb(255, 255, 255);
  font-size: 16px;
  min-height: 40px;
  min-width: 60px;
  padding: 4px 15px;
  margin: 6px 9px 5px -1px;
  font-weight: 500;
  line-height: 1.5;
}
.css-1m5o92u1 {
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: #db1400;
  color: white;
  font-size: 16px;
  min-height: 40px;
  min-width: 60px;
  padding: 4px 15px;
  margin: 6px 9px 5px -1px;
  font-weight: 500;
  line-height: 1.5;
}
.dragging {
  background-color: #e0e0e0;
}
   .trash-icon {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      color: red;
    }
    svg#deleteArea {
      display: none;
      width: 135px;
      height: 135px;
      position: absolute;
      right: 57px;
      bottom: 0;
      top: 100px;
  }
  
  svg#deleteArea:hover {
      display: block;
    }

    #parentSVG {
      height:100vh;
      width:99.5%;
      border-style:solid;
      position:absolute;
      }
      
      #mainSVG1 {
      float:left;
      width:100%;
      /*position:absolute;*/
      border-style:solid;
      }
      #mainSVG2 {
      float:left;
      width:49.5%;
      /*position:absolute;*/
      height:100vh;
      border-style:solid;
      }
      
      #cloneableObject {
      cursor:pointer;
      }
   .wrapped_object:after {
        content: "";
        position:absolute;
        left: 0;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color:#03010100;
      }
.css-1eq84o{
  display: flex;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(134, 134, 134);
    border-radius: 2px;
    width: 100%;
    outline: transparent solid 1px;
    outline-offset: -2px;
    transition-duration: 100ms;
    transition-property: border, outline;
    max-height: 28px;
}
.css-in32s3s{
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-overflow: ellipsis;
    width: 100%;
    background: transparent;
    border: none;
    color: rgb(30, 30, 30);
    height: auto;
    margin: 0px;
    padding-top: 4px;
    padding-bottom: 5px;
    padding-inline: 8px;
}
.css-s3217f {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  margin: 0px;
  padding: 0px;
  color: rgb(209, 50, 57);
}
.css-164r41r {
  margin-top: 8px;
}

.resize-handler {
  height:10px;
  width:10px;
  background-color:#0055ff;
  position:absolute;
  border-radius:100px;
  border:1px solid #ffffff;
  user-select:none;
  display:none;
}

.resize-handler:hover {background-color:#0000cc;}
.resize-handler.rotate {cursor: url('https://findicons.com/files/icons/1620/crystal_project/16/rotate_ccw.png'), auto;}
.css-q3fgw5 {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0px auto;
  max-width: none;
  min-width: 320px;
  position: relative;
  color: rgb(255, 255, 255);
  height: 52px;
  padding-inline: 8px 16px;
}
.css-1vsgmeb {
  -webkit-box-align: center;
  display: flex;
  height: inherit;
  flex: 1 1 25%;
  min-width: 196px;
  align-items: flex-end;
}
.css-zx8agq {
  margin-right: 12px;
}
.css-x3ryoo {
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  display: flex;
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  height: 52px;
  letter-spacing: 0.66px;
  padding-inline: 12px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s ease 0s, color 0.2s ease 0s;
  white-space: nowrap;
  color: rgb(51, 51, 51);
  padding: 0px 12px;
}
.css-1k6s299 {
  display: flex;
  margin-inline-end: 6px;
  margin: 0px;
}
.css-1pdgtej {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.css-x3ryoo::after {
  inset-inline-start: 0px;
  background-color: transparent;
  bottom: 14px;
  border: none;
  content: "";
  display: block;
  height: 2px;
  inset-inline: 0px;
  margin: auto;
  position: absolute;
  transition: background-color 0.2s ease 0s;
  width: calc(100% - 24px);
}
.css-1k6s299 svg, .css-1k6s299 img {
  fill: currentcolor;
  width: 16px;
}
.css-1e1ivkv header p {
  border-left: 1px solid;
  margin-left: 4px;
  padding-left: 16px;
}

.css-u2y338 {
  display: block;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(51, 51, 51);
}
.css-11jt1m5 {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 1 25%;
  -webkit-box-pack: end;
  justify-content: flex-end;
  min-width: 243px;
}
.css-14j54of {
  color: black;
}
.css-kpt29i {
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  display: flex;
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  height: 52px;
  letter-spacing: 0.66px;
  padding-inline: 12px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s ease 0s, color 0.2s ease 0s;
  white-space: nowrap;
  color: rgb(51, 51, 51);
}
.css-gusat5 {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: rgb(51, 51, 51);
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color;
  transition-timing-function: ease;
  width: auto;
  min-height: 34px;
  min-width: 34px;
  padding: 6px 18px;
  background-color: transparent;
  color: rgb(51, 51, 51);
}
.css-bfq68w {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(36, 99, 209);
  color: rgb(255, 255, 255);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 4px 14px;
  border-radius: 20px;
}
.css-x3ryoo {
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  display: flex;
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  height: 52px;
  letter-spacing: 0.66px;
  padding-inline: 12px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s ease 0s, color 0.2s ease 0s;
  white-space: nowrap;
  color: rgb(51, 51, 51);
  padding: 0px 12px;
}


.css-x9h4z9{
  max-width: none;
}
.css-x9h4z9 button {
  margin-left: 12px;
}
.css-3rwt7q {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(249, 249, 249);
  border-color: rgb(204, 204, 204);
  color: rgb(30, 30, 30);
  font-size: 13px;
  min-height: 34px;
  min-width: 34px;
  padding: 7px 18px;
}
.css-16hz1ch {
  pointer-events: none;
}
.css-1n4kelk {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(255, 206, 0);
  color: rgb(30, 30, 30);
  font-size: 13px;
  min-height: 34px;
  min-width: 34px;
  padding: 7px 18px;
}
.css-jus432{
  display: flex;
  justify-content: flex-end;
  padding: 5px 5px;
 
}
.ink-page-title {
  margin-bottom: 1rem;
  display: inline-flex;
  justify-content: center;
}
.css-1uxywf6 {
  margin: 0px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25;
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
}
.css-1b0kgxe {
  background: none;
  border: none;
  border-radius: 0px;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: normal;
  line-height: inherit;
  padding: 0px;
  margin: 0px;
  text-align: inherit;
  text-decoration: none;
  color: rgb(2, 2, 2);
}
.css-8rgqp2-HEADER_BAR_LEFT {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
}
.css-cskqfi-EmptyHeader {
  margin-left: 10px;
}
.css-51fonb-HEADER_APP_DESCRIPTION_NON_PROD {
  height: 40px;
  padding-top: 4px;
  text-decoration: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-1v7l42u {
  inset-inline-start: 0px;
  position: absolute;
  top: 0px;
  z-index: 200;
}
.css-mvyop0 {
  display: block;
  transform: translateZ(0px);
  width: max-content;
}
.css-1f1yei0 {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px;
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: right top;
  z-index: -1;
}
.css-1iiwxfh {
  color: rgb(30, 30, 30);
  float: none;
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  overflow: auto;
  overscroll-behavior: contain;
  padding: 0px 1px;
  text-align: start;
}
.css-1iq8xl9 ul[data-qa="button-actions"] {
  padding: 14px;
}
.css-vkwu48 {
  list-style: none;
  margin: 8px 0px;
  padding: 0px;
  display: block;
  text-align: center;
}
.css-129ohls {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(249, 249, 249);
  border-color: rgb(204, 204, 204);
  color: rgb(30, 30, 30);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  margin-bottom: 5px;

}
.css-16hz1ch {
  pointer-events: none;
}
.css-1kbafqs {
  display: block;
  border-bottom: 1px solid rgb(233, 233, 233);
}
.css-vkwu48 {
  list-style: none;
  margin: 8px 0px;
  padding: 0px;
}
.css-1xasier {
  display: block;
  margin: 0px;
  padding: 0px;
}
.css-75irmu {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  background: transparent;
  border: 0px;
  border-radius: 0px;
  color: rgb(30, 30, 30);
  cursor: pointer;
  display: flex;
  min-height: 30px;
  padding: 4px 16px;
  position: relative;
  text-align: start;
  text-decoration: none;
  transition-property: background-color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  width: 100%;
}
.css-9cbk6x {
  flex: 1 1 auto;
}
.css-1kbafqs:last-child {
  display: none;
}
.css-it5yrk {
  border: 0px;
  background-color: rgb(255, 255, 255);
  color: rgb(134, 134, 134);
  padding: 20px 13px 10px;
  position: relative;
}
.css-guny5b {
  border: 0px;
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  position: absolute;
}
.css-1se1tr6 {
  border-width: 0px 0px 2px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  background-color: rgb(255, 255, 255);
  color: rgb(134, 134, 134);
  padding: 20px 13px 10px;
  position: relative;
  opacity: 1;
  border-bottom-style: solid;
  border-bottom-color: rgb(44, 110, 225);
}
.css-35sd45ul{
  margin-left: 24px;
  top: 0px;
  position: absolute;
  left: calc(50% - 57px);
}
/* styles.css */
#cookie-disclaimer {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  width: 100%;
  border-bottom: 2px solid #2752a5;
  margin: 0;
  background-color: #333;
  font-size: 14px;
  color: #FFF;
  min-height: 30px;
  line-height: 20px;
  top: 0;
}

#cookie-disclaimer div {
  margin: 0 auto;
  max-width: 1506px;
  padding: 5px 24px;
}

#cookie-disclaimer a {
  color: #86c4fd;
  font-size: 14px;
}

#cookie-disclaimer .btn {
  background-color: #86c4fd;
  color: #333;
  font-size: 14px;
  padding: 0 27px;
  margin: 0 10px;
  border: 0;
  border-radius: 2px;
  text-transform: uppercase;
  font-family: 'Maven Pro', Arial, sans-serif !important;
  font-weight: bold;
  float: right;
  line-height: 20px;
  height: 20px;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 992px) {
  #cookie-disclaimer div {
    padding: 5px 25px;
    font-size: 12px;
  }

  #cookie-disclaimer a {
    font-size: 12px;
  }

  #cookie-disclaimer .btn {
    font-size: 11px;
    padding: 0 20px;
  }
}
#action-bar-wrapper, #action-bar-bottom-wrapper, #action-bar-wrapper .icon-menu {
  color: #FFFFFF !important;
}
#action-bar-wrapper {
  min-height: 48px;
}
#action-bar-wrapper {
  color: #fff;
  background: #005cb9;
  line-height: 16px;
}
#action-bar-wrapper .wrapper {
  position: relative;
  padding: 7px 24px;
  max-width: 1506px;
  margin: 0 auto;
}
#action-bar-wrapper #action-bar-consent-control {
  display: table-cell;
  width: 100%;
}
#action-bar-wrapper .row {
  height: 36px;
  display: table-row;
}
#container #action-bar .cell, #container #footer .cell, #container #toolbar .cell, #container #welcome .cell {
  display: table-cell;
}
#action-bar-wrapper .pointer.ltr {
  margin-right: 12px;
}

.clear-float {
  clear: both;
}
#container #action-bar .cell, #container #footer .cell, #container #toolbar .cell, #container #welcome .cell {
  display: table-cell;
}
.visible-inline-xs, .visible-xs {
  display: none!important;
}

#action-bar-wrapper .btn-minor {
    color: inherit;
}

#action-bar-wrapper .btn {
  font-size: 15px;
}
.css-gusat5s {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: rgb(51, 51, 51);
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color;
  transition-timing-function: ease;
  width: auto;
  min-height: 34px;
  min-width: 34px;
  padding: 6px 18px;
  
  color: #333333 !important;
  border-color: #ffc820 !important;
}
.css-ms32d5{
  width: 100%;
    position: relative;
    display: flow;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.css-23sad4{
  position: relative;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  height: 75vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.btn-active{
  border-style: solid;
    border-bottom: 3px solid #005cb9;
}
.loadingPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Set a high z-index to ensure it's above other elements */
}

.spinner {
  border-width: 16px;
  border-style: solid;
  border-color: rgb(52, 152, 219) rgb(243, 243, 243) rgb(243, 243, 243);
  border-image: initial;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.listHeaderWrap {
  background-color: #fff;
}
.css-jcpkb3 {
  min-height: 80px;
}
@media (min-width: 768px) {
  .listHeaderWrap-templates .listHeader_titleRow {
      display: flex;
      justify-content: space-between;
  }
}

.listHeader_titleRow {
  padding: 0 24px 14px 24px;
  position: relative;
  top: 22px;
}

.m-bottom-xx-small {
  margin-bottom: 8px !important;
}

.listHeader_heading {
  line-height: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (min-width: 768px) {
  .listHeaderWrap-templates .listHeader_filter {
      min-width: 450px;
      position: relative;
  }
}

@media (min-width: 768px) {
  .cheshire-templates {
      border-right: none;
      border-top: none;
      max-width: 469px;
      padding: 24px 0 17px;
      position: absolute;
      right: -24px;
      top: -24px;
  }
}

.cheshire-templates .cheshire_visibleContent.cheshire_visible-active {
  z-index: 0;
}

.templates_filterBtn {
  margin-right: 4px;
}

.listHeader_search {
  display: inline-block;
  margin-right: 14px;
  width: 300px;
}
.css-5gs0ys {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  overflow-wrap: break-word;
  color: rgb(30, 30, 30);
  display: block;
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.css-k008qs {
  display: flex;
}

.css-1eq84o {
  display: flex;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(134, 134, 134);
  border-radius: 2px;
  width: 100%;
  outline: transparent solid 1px;
  outline-offset: -2px;
  transition-duration: 100ms;
  transition-property: border, outline;
  max-height: 28px;
}

.css-1nenmzo {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
  min-width: 30px;
  color: rgb(134, 134, 134);
}



.css-1ez4hss {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-overflow: ellipsis;
  width: 100%;
  appearance: none;
  background: transparent;
  border: none;
  color: rgb(30, 30, 30);
  height: auto;
  margin: 0px;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-inline: 0px 8px;
  outline: none;
}

.css-qhnch2 {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  color: rgb(30, 30, 30);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 4px 14px;
}

.css-1obf64m {
  display: block;
  overflow: auto;
}
.css-1u2njnl {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  background-color: rgb(255, 255, 255);
  border-collapse: separate;
  border-spacing: 0px;
  border-top: 1px solid rgb(233, 233, 233);
  color: rgb(30, 30, 30);
  width: 100%;
}
.css-3div8j {
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
}
.css-cir4u5 {
  background-color: rgb(255, 255, 255);
  transition: background-color 0.2s ease 0s;
}
.css-cir4u5 td:first-of-type {
  border-inline-start-color: rgb(36, 99, 209);
}
.css-1u2njnl td:nth-of-type(1) {
  width: 2px;
  text-align: start;
}
.css-1u2njnl tr:last-of-type th:nth-of-type(3) {
  width: 55%;
  text-align: start;
}
.css-1u2njnl tr:last-of-type th:nth-of-type(4) {
  width: 20%;
  text-align: start;
}
.css-17bqg1m:first-of-type {
  border-inline-start: 3px solid transparent;
  padding-inline-start: 21px;
}
.css-17bqg1m {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid rgb(233, 233, 233);
  padding: 0px 12px;
  text-transform: inherit;
  line-height: 0 !important;
}
.css-17bqg1m:last-child {
  border-inline-end: none;
  padding-inline-end: 24px;
}
.css-rhk3of {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  border-bottom: 1px solid rgb(233, 233, 233);
  padding: 8px 12px;
  text-transform: inherit;
}
.css-1u2njnl tr:last-of-type th:nth-of-type(5) {
  width: 16%;
  text-align: start;
}
.css-85irbq:first-of-type {
  border-inline-start: 3px solid transparent;
  padding-inline-start: 21px;
}
.css-1u2njnl td {
  text-align: start;
}
.css-85irbq {
  border-bottom: 1px solid rgb(233, 233, 233);
  padding: 10px;
  font-size: 11px;
}
.css-1mwciyk {
  position: relative;
  inset: 8px 0px 0px;
  padding: 5px;
}

.css-1e0bg48 {
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  flex-direction:row;
  min-height: 18px;
  min-width: 18px;
  position: relative;
}
.css-18q90e8 {
  position: relative;
  left: 19px;
    z-index: 1;
    bottom: 3px;
    width: 20px;
}
.css-1xrwcn5 {
  align-items: flex-start;
  color: rgb(30, 30, 30);
  display: flex;
  min-height: 18px;
  min-width: 18px;
  position: relative;
}
.css-1xrwcn5::before {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(134, 134, 134);
  border-radius: 2px;
  content: "";
  cursor: pointer;
  display: block;
  flex: 0 0 auto;
  height: 18px;
  transition-duration: 0.2s;
  transition-property: border;
  transition-timing-function: ease;
  width: 18px;
}
.css-1xrwcn5 > span::before {
  border-color: transparent;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  content: "";
  display: block;
  height: 18px;
  inset-inline-start: 0px;
  position: absolute;
  top: 0px;
  transition-duration: 0.2s;
  transition-property: border;
  transition-timing-function: ease;
  width: 18px;
}

.css-szbaom {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  min-height: 18px;
  padding-inline-start: 10px;
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: static;
  white-space: nowrap;
  width: 1px;
}

.css-rpuriw {
  width: 0px;
  min-width: 100%;
}
.table_copy-link {
  cursor: pointer;
}
.css-1u2njnl td:nth-of-type(4) {
  width: 20%;
  text-align: start;
}
.css-1u2njnl td:nth-of-type(5) {
  width: 25%;
  text-align: start;
}
.css-85irbq:last-child {
  border-inline-end: none;
  padding-inline-end: 24px;
}
.css-1u2njnl td {
  text-align: start;
}
.css-1hyfx7x {
  display: none;
}
.css-79elbk {
  position: relative;
}
.css-1mw9iin {
  display: inline-flex;
  width: 100%;
}
.css-1mw9iin > button {
  height: inherit;
}
.css-lzi6zi {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(249, 249, 249);
  border-color: rgb(204, 204, 204);
  color: rgb(30, 30, 30);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 4px 14px;
  border-radius: 2px 0px 0px 2px;
  border-start-start-radius: 2px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border-end-start-radius: 2px;
  border-inline-end: none;
  width: 100%;
}
.css-dos3sa3 {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(249, 249, 249);
  border-color: rgb(204, 204, 204);
  color: rgb(30, 30, 30);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 4px 14px;
  border-radius: 2px 0px 0px 2px;
  border-start-start-radius: 2px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border-end-start-radius: 2px;
  border-inline-end: none;
  width: 100%;
}
.css-16hz1ch {
  pointer-events: none;
}
.css-dqnnaa {
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: rgb(249, 249, 249);
  border-color: rgb(204, 204, 204);
  color: rgb(30, 30, 30);
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 0px;
  border-radius: 0px 2px 2px 0px;
  border-start-start-radius: 0px;
  border-start-end-radius: 2px;
  border-end-end-radius: 2px;
  border-end-start-radius: 0px;
  border-inline-start-color: rgb(134, 134, 134);
}
.css-1mw9iin > button {
  height: inherit;
}
.css-1pdgtej {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.css-1hz9l7 {
  line-height: 0.66px;
  pointer-events: none;
  margin-inline: 6px -4px;
  margin: 0px;
}
.css-1yjj09l {
  height: 0px;
  visibility: hidden;
}
.u-ellipsis {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 229px;
}
.table_copy-link {
  cursor: pointer;
}
.backColor {
  background: #26bf04;
  border: 1px solid #c4c4c4;
  width: 10px;
  height: 8px;
}
.backColorB {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  width: 10px;
  height: 8px;
}
.fonts{
  font-size: 9px;
  width: 10px;
  height: 8px;
  justify-content: center;
  align-items: center;
}

.pull-right{
  float:right;
}
.pull-left{
  float:left;
}


.capitalize::first-letter {
  text-transform: uppercase;
}

.css-list{
  margin: 12px 0px 10px;
}
.css-elfzg7 {
  align-items: flex-end;
  display: flex;
  flex-flow: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-block-end: 16px;
}
.css-iekl2y {
    font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    overflow-wrap: break-word;
    color: rgba(25, 24, 35, 0.9);
    display: block;
}
.css-mvp4wi {
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(25, 24, 35, 0.9);
  margin-inline-start: 8px;
}
.css-79elbk {
  position: relative;
}

.progress-bar {
  appearance: none;
  background: rgba(25, 24, 35, 0.5);
  border-radius: 2px;
  display: block;
  height: 4px;
  overflow: hidden;
  width: 100%;
  border: 0px none;
  position: absolute;
  top: 3px;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.red {
  background: red;
}    
.css-1aelg5e {
  appearance: none;
  background: rgba(25, 24, 35, 0.5);
  border-radius: 2px;
  display: block;
  height: 4px;
  overflow: hidden;
  width: 100%;
  border: 0px none;
  position: absolute;
  top: 3px;
}
.css-1o7ox21 {
  height: 8px;
  stroke: rgb(0, 105, 236);
  stroke-width: 8px;
}
.cSKbWOeYvUBPCWIgqt3A {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.css-5xdky1 {
  max-width: 450px;
  margin: 0px auto;
}
.css-ku1ana {
  background: none;
  border: none;
  border-radius: 0px;
  color: rgb(0, 105, 236);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: normal;
  line-height: inherit;
  padding: 0px;
  margin: 0px;
  text-align: inherit;
  text-decoration: underline;
}
.css-7gzz9h {
  text-decoration: inherit;
}

.css-1frfvw8 {
  margin-block: 0px;
  margin-inline: 0px;
  padding-block: 0px;
  padding-inline: 0px;
}

.css-7r1z99 {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  display: inline-block;
  vertical-align: top;
  width: 240px;
}

.css-1shlyym {
  border-radius: 2px 2px 0px 0px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.css-1shlyym::before {
  content: "";
  display: block;
  padding-top: 66.6667%;
  width: 100%;
}

.css-1qq4o7v {
  -webkit-box-align: center;
  align-items: center;
  inset: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: 100;
  transition: background 0.1s ease 0s;
}
.css-1qq4o7v:hover {
  background: #00000024;
}

.css-1qq4o7vbtns {
  opacity: 0;
  transition: opacity 0.2s ease-in-out; /* Smooth opacity transition */
}

.css-1qq4o7v:hover .css-1qq4o7vbtns {
  opacity: 1; /* Show button when parent is hovered */
}

.css-1qq4o7v .css-1rc02wc {
  font-weight: 600;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  font-family: "Maven Pro", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.66px;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  font-size: 12px;
  min-height: 28px;
  min-width: 28px;
  padding: 4px 14px;
  // background-color: transparent;
  background-color: #353535ad;
  border-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}
.css-1qq4o7v .css-1rc02wc:hover {
  background-color: #353535a1;
  border: none;
}
.css-1ixbp0l {
  pointer-events: none;
  color: white;
}
.css-1am6aje {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.css-78lt08 {
  display: inline-flex;
  margin: 4px;
  position: absolute;
  z-index: 5;
  right: 0px;
  top: 0px;
}
.css-naupo3 {
  -webkit-box-align: center;
  align-items: center;
  border-radius: 16px;
  display: inline-flex;
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.2px;
  line-height: 1.5;
  min-width: 30px;
  padding: 0px 8px;
  white-space: nowrap;
  background-color: rgb(244, 244, 246);
  color: rgb(25, 24, 35);
}

.css-32d82q {
  inset: 0px;
  position: absolute;
}

.css-150ral2 > img {
  display: inline-block;
  max-height: 106px;
  max-width: 142px;
}

.css-1shlyym img {
  border-radius: 1.5px 1.5px 0px 0px;
  inset: 0px;
  margin: auto;
  position: absolute;
}

.css-1sz9cjp {
  border: 0px;
  max-width: 100%;
  max-height: 48px;
}

.css-6t6lyd + .css-6t6lyd {
  border-top: 1px solid rgb(233, 233, 233);
}
.css-ipf7y1 {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-flow: row;
}

.css-60g013 {
  width: 75%;
  font-weight: bold;
  margin: 10px 10px 10px 15px;
  padding: 3px 0px;
}

.w4EGE1yYMfGWs7nXAypr {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.css-eolcwi {
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  color: rgba(25, 24, 35, 0.9);
  min-height: 40px;
  padding-inline-start: 11px;
  padding: 0px;
  min-width: 40px;
}

.css-8qo53f button {
  padding-inline: 0px;
  width: 30px;
}

.css-g4t7oo {
  line-height: 0.66px;
  pointer-events: none;
  margin-inline: 0px 8px;
  margin: 0px;
  padding: 7px;
}

.css-eolcwi svg {
  fill: rgba(25, 24, 35, 0.65);
}

.L049JjcqkWxmi1SN1MIQ {
  padding-top: 16px !important;
}

.Q8oHp7bEjhmehslY4uZL {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}

.css-1joc5e2 {
  -webkit-box-align: start;
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  height: 100%;
  width: 100%;
}

.css-4rf33w {
  max-width: 300px;
  min-width: 200px;
  padding-right: 30px;
}

.css-deihcw > img {
  padding: 4px;
 
}

.css-155374s {
  max-width: 300px;
  min-width: 200px;
  padding-right: 30px;
}
.qwmnuOGOC1wmlU6tG8yQ {
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  display: inline-block;
  height: 28px;
  width: 28px;
}

.qRVNXulY64Dd0f5OBGQb {
  padding-bottom: 24px !important;
}

.css-1t3ymg9 {
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  border-color: rgba(25, 24, 35, 0.65);
  color: rgba(25, 24, 35, 0.9);
  min-height: 40px;
  min-width: 80px;
  padding: 4px 15px;
}

.css-16hz1ch {
  pointer-events: none;
}



@media screen and (max-height: 403px) {
  .css-qf5tof {
      flex: 1 0 auto;
  }
}

.css-qf5tof {
  flex: 1 1 auto;
  overflow-y: auto;
}

@media screen and (max-height: 403px) {
  .css-ix6jir {
      margin: 0px;
      padding-top: 0px !important;
  }
}

.css-ix6jir {
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  overflow-y: hidden;
  padding: 8px 48px 0px;
}

.css-fta723 {
  margin-top: 12px;
  margin-bottom: 32px;
}
.RcWeCMK_5wbOQGDuetx7 {
  padding-right: 16px !important;
}

.h6Dyc_0RkOthtRStRg3T {
  height: auto !important;
}

.U4ua1_aVL9FEdgqUhSB2 {
  margin-top: 8px !important;
}

.Aq4fEEQKY10alPYc193i {
  font-weight: bold;
}
.G7jaZc86XiIKysmIyFAp {
  border: 1px solid #ccc;
  border-radius: 2px;
  display: inline-block;
  margin-bottom: 10px;
}

.G7jaZc86XiIKysmIyFAp .JcZQ8ANnx3vqP6Jqhfvw {
  display: table-row;
}

.G7jaZc86XiIKysmIyFAp .FdWpOmwtO778cTuj86Pw {
  display: inline-block;
  height: auto;
  text-align: center;
  vertical-align: middle;
  width: 148px;
  bottom: 12px;
}

.vp7p4e64DoSIaGefRpoO {
  display: inline-block;
  max-height: 38px;
  max-width: 148px;
}

.css-12s44sk {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.css-goyd0e {
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  border-color: rgba(25, 24, 35, 0.65);
  color: rgba(25, 24, 35, 0.9);
  min-height: 40px;
  min-width: 80px;
  padding: 4px 15px;
}

.css-16hz1ch {
  pointer-events: none;
}

.zkX22XJv_CDqK_o6beoO {
  padding-top: 20px !important;
}



.OyGBHIBSY5ycu__B0Zi3 {
  margin-top: 12px !important;
}

.css-pkaues {
  background: transparent;
  border: none;
  color: rgb(30, 30, 30);
  display: flex;
  padding: 0px 0px 7px;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  text-align: left;
}

.css-1vmmi7g {
  border-radius: 36px;
  height: 40px;
  width: 40px;
  margin: 3px 8px 3px 0px;
  flex-shrink: 0;
}

.css-1hj83rq {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: inherit;
  margin: 3px 0px 2px;
}

.css-10o70l8 {
  color: rgb(102, 102, 102);
  font-size: 13px;
  text-transform: uppercase;
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.css-13szokc {
  border-radius: 36px;
  height: 40px;
  width: 40px;
  margin: 3px 8px 3px 0px;
  flex-shrink: 0;
  border: 1px solid rgb(204, 204, 204);
}

.css-1hj83rq {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: inherit;
  margin: 3px 0px 2px;
}

.css-10o70l8 {
  color: rgb(102, 102, 102);
  font-size: 13px;
  text-transform: uppercase;
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.GRfnMhqudHi6fULN6PJw {
  padding-left: 16px !important;
}

.a3aKtBKSUKYtdUAfWsyt {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.cugeZBnxw64qeATn_7gS {
  padding-left: 20px !important;
}

.css-oxvvjo {
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  -webkit-box-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  border-color: rgba(25, 24, 35, 0.65);
  min-height: 40px;
  min-width: 80px;
  padding: 4px 15px;
  background-color: rgba(25, 24, 35, 0.1);
  color: rgba(25, 24, 35, 0.9);
  transition-duration: 0.15s;
  border-radius: 2px 0px 0px 2px;
  border-start-start-radius: 2px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border-end-start-radius: 2px;
  border-inline-end: none;
}

.css-e4cmar {
  font-family: "DS Indigo", DSIndigo, "Neue Haas Grotesk", NeueHaasGrotesk, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  -webkit-box-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  overflow: visible;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  background-color: transparent;
  border-color: rgba(25, 24, 35, 0.65);
  color: rgba(25, 24, 35, 0.9);
  min-height: 40px;
  min-width: 80px;
  padding: 4px 15px;
  border-radius: 0px 2px 2px 0px;
  border-start-start-radius: 0px;
  border-start-end-radius: 2px;
  border-end-end-radius: 2px;
  border-end-start-radius: 0px;
  border-inline-start-color: rgba(25, 24, 35, 0.65);
}

.css-16hz1ch {
  pointer-events: none;
}

.css-fta723 {
  margin-top: 12px;
  margin-bottom: 32px;
}

// banner
.css-mjxw24 {
  background: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000 !important;
}

.css-1cjmmev {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

@media (min-width: 76.25rem) {
  .css-9brd0r {
      padding: 0 2.5rem;
  }
}
@media (min-width: 76.25rem) {
  .css-19njzqs {
      padding: 0 2.5rem;
  }
}
@media (min-width: 64rem) {
  .css-9brd0r {
      padding: 0 2rem;
  }
}

@media (min-width: 48rem) {
  .css-9brd0r {
      padding-left: 40px;
      padding-right: 40px;
  }
}
@media (min-width: 64rem) {
  .css-19njzqs {
      padding: 0 2rem;
  }
}
@media (min-width: 64rem) {
  .css-9brd0r {
      padding-bottom: 0;
      padding-top: 0;
  }
}

.css-9brd0r {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #007bff;
  color: #ffffff;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding: 0 1.5rem;
}
.css-1grwys7 {
  margin: 0 auto;
  max-width: none;
  width: 100%;
}
@media (min-width: 64rem) {
  .css-l63qg9 {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
  }
}

.css-l63qg9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: auto;
  max-width: 93.75rem;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
  padding: 0.125rem 0;
  gap: 1.5rem;
}

@media (min-width: 64rem) {
  .css-y3devv {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
  }
}

.css-y3devv {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}
.css-fsu8aj, .css-fsu8aj:visited {
  color: #ffffff;
}
.css-fsu8aj, .css-fsu8aj:visited {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.css-fsu8aj::before {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #CBC2FF;
  border-radius: 0.25rem;
  border-top: 0.0625rem solid #CBC2FF;
  color: #130032;
  content: 'New';
  display: block;
  font-size: 0.625rem;
  font-weight: 600;
  height: 14px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  line-height: 1.4;
  margin-right: 0.5rem;
  padding: 0.125rem 0.25rem;
  text-transform: uppercase;
}

.css-g4mfjf {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}
.css-4ebx1o {
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.css-g4mfjf::after {
  background: #ffffff;
  bottom: 0;
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  -webkit-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
  width: 0;
}

@media (min-width: 64rem) {
  .css-1lhb9go {
      margin-left: auto;
  }
}

.css-1lhb9go {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style-type: none;
  margin: 0;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 0;
  white-space: nowrap;
  gap: 1.5rem;
}
.css-1olokg6 {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 1.5;
}
.css-sxqjov, .css-sxqjov:visited {
  color: #ffffff;
}
.css-sxqjov, .css-sxqjov:visited {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-1111xiq {
  display: none;
  padding: 0.25rem 0;
}

@media (min-width: 45rem) {
  .css-1111xiq {
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
  }
}
.css-1jjw43b {
  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 1.5;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.25rem;
}

@media (min-width: 64rem) {
  .css-1lhb9go li:first-of-type {
      margin-left: 1.5rem;
  }
}
.css-fpl025 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.25rem 0;
}
@media (min-width: 45rem) {
  .css-19njzqs {
      padding: 0 1.5rem;
  }
}

@media (min-width: 48rem) {
  .css-19njzqs {
      padding-left: 40px;
      padding-right: 40px;
  }
}
.css-b9x1zj {
  background-color: #ffffff;
  position: relative;
}
.css-19njzqs {
  background: #ffffff;
  color: #130032;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  overflow: visible;
  padding: 0 1rem;
}

.css-1grwys7 {
  margin: 0 auto;
  max-width: none;
  width: 100%;
}

@media (min-width: 76.25rem) {
  .css-3wbi5w {
      padding: 0;
  }
}

.css-3wbi5w {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: auto;
  max-width: 93.75rem;
  min-height: -webkit-max-content;
  min-height: -moz-max-content;
  min-height: max-content;
  padding: 0rem 0;
}

@media (min-width: 76.25rem) {
  .css-1jgav8z {
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
  }
}

.css-1jgav8z {
  display: flex;
  vertical-align: middle;
}
.css-1ra4uzk {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.css-6r4a48 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.css-1ra4uzk::after {
  background: #4C00FF;
  bottom: 0;
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  -webkit-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
  width: 0;
}

.css-6r4a48 svg {
  height: auto;
  width: 8.25rem;
}
@media (min-width: 76.25rem) {
  .css-kkuep2 {
      display: none;
  }

  .css-kcwioq{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 76.25rem) {
  .css-81kfzn  {
      display: none;
  }
  .css-kcwioq{
    display: none;
  }
}

@media (max-width: 768px) {
  /* Hide all elements except logo and Try for Free button */
  .css-1grwys7 ul,
  .css-238vhp a:not(.css-1vokhr7) {
    display: none;
  }

  /* Center the logo */
  .css-1jgav8z {
    justify-content: center;
  }

  /* Show Try for Free button */
  .css-1vokhr7 {
    display: block;
  }

  /* Style the Try for Free button to align next to the logo */
  .css-1grwys7 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.css-kkuep2 {

  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;

  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.css-1p7pvml {
  color: #130032;
}

.css-1p7pvml svg {
  vertical-align: baseline;
}

.css-kcwioq {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  gap: 1.5rem;
  list-style-type: none;
  margin: 0 auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 0;
  // position: relative;
  position: inherit;
  margin-left: 10px;
    left: 42px;
}
@media (min-width: 76.25rem) {
  .css-81kfzn {
      margin: 0 3rem 0 0;
  }
}

@media (min-width: 45rem) {
  .css-81kfzn {
      margin: 0 1.5rem;
  }
}
.css-81kfzn, .css-81kfzn:visited {
  outline-offset: 2px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-81kfzn {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.25rem;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  margin: 0 1rem;
}
@media (min-width: 76.25rem) {
  .css-853953 {
      display: inline-block;
  }
}



.css-qowjig {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.css-nx1dk9 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 1.5;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0.25rem;
  color: #130032BF;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 0.2rem;
  margin: 0;
  padding: 1.5rem 0;
  position: relative;
}
.css-nx1dk9::after {
  background-color: transparent;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}
.css-1d1unjo {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-149lvuf {
  visibility: hidden;
  pointer-events: none;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}
@media (min-width: 76.25rem) {
  .css-1crqsg9 {
      padding-bottom: 2.5rem;
      padding-top: 2.5rem;
  }
}

@media (min-width: 48rem) {
  .css-1crqsg9 {
      padding-left: 40px;
      padding-right: 40px;
  }
}

@media (min-width: 64rem) {
  .css-1crqsg9 {
      padding-bottom: 0;
      padding-top: 0;
  }
}

.css-1crqsg9 {
  background: #ffffff;
  color: #130032;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 2rem 4rem 0 #13003233;
  cursor: auto;
  max-height: calc(100vh - 175px);
  overflow-y: scroll;
  width: 100%;
}

.css-1grwys7 {
  margin: 0 auto;
  max-width: none;
  width: 100%;
}

.css-1rfl3aw {
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin: 0 auto;
  max-width: 93.75rem;
}
.css-1vokhr7, .css-1vokhr7:visited {
  color: rgb(255, 255, 255);
}

@media (min-width: 45rem) {
  .css-1vokhr7 {
      width: unset;
  }
}

@media (min-width: 76.25rem) {
  .css-1vokhr7 {
      min-height: 2.5rem;
  }
}

.css-1vokhr7 {
  appearance: none;
  background: #007bff;
  border: none;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-family: inherit;
  font-optical-sizing: inherit;
  font-kerning: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  overflow: visible;
  -webkit-font-smoothing: antialiased;
  outline-offset: 2px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: inline-flex;
  gap: 0.25rem;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  min-height: 44px;
  min-width: 44px;
  text-decoration: none;
  transition-duration: 100ms;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  width: 100%;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  line-height: 1.5;
  color: rgb(255, 255, 255);
  padding: 0.75rem 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
.css-a8kaei {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #130032;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  gap: 0.5rem;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@media (min-width: 47rem) {
  .css-238vhp:not(:last-of-type) {
      display: inline;
  }
}

@media (min-width: 76.25rem) {
  .css-lsiqut {
      min-height: 2.5rem;
  }
}

.css-lsiqut {
  appearance: none;
  background: transparent;
  border: none;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-family: inherit;
  font-optical-sizing: inherit;
  font-kerning: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  overflow: visible;
  -webkit-font-smoothing: antialiased;
  outline-offset: 2px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: inline-flex;
  gap: 0.25rem;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  min-height: 44px;
  min-width: 44px;
  text-decoration: none;
  transition-duration: 100ms;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  width: 100%;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  line-height: 1.5;
  box-shadow: transparent 0px 0px 0px 0.0625rem inset;
  color: #007bff;
  padding: 0.75rem 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
@media (min-width: 45rem) {
  .css-1w0un78 {
      padding: 0px 1.5rem 1.5rem;
  }
}

.css-1w0un78 {
  color: rgba(19, 0, 50, 0.75);
  padding: 0px 1rem 1.5rem;
}
.css-vk4aun {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  text-indent: 0px;
  display: grid;
  gap: 0.5rem;
}
.css-14zxrix {
  color: rgba(19, 0, 50, 0.75);
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
}
.css-1skinc4 {
  background: rgba(19, 0, 50, 0.15);
  border: none;
  display: block;
  margin: 0px;
  height: 1px;
  width: 100%;
}

@media screen and (min-width: 45rem) {
  .css-1f75d59 {
      padding: 1rem 1.5rem 2.5rem;
  }
}
.css-17wku3l {
  list-style-type: none;
  margin: 0px 0px 1rem;
  padding: 0px;
  text-indent: 0px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  display: grid;
  gap: 1rem;
}
.css-17wku3l {
  list-style-type: none;
  margin: 0px 0px 1rem;
  padding: 0px;
  text-indent: 0px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  display: grid;
  gap: 1rem;
}
.css-s5oykz::after {
  background-color: transparent;
  bottom: 0px;
  content: "";
  display: flex;
  -webkit-box-pack: center;
  height: 2px;
  left: 0px;
  position: absolute;
  width: 100%;
}
.css-1w0un78 button {
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  font-optical-sizing: inherit;
  font-kerning: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0px;
  overflow: visible;
  width: auto;
  cursor: pointer;
  outline: none;
}

.css-s5oykz {
  appearance: none;
  background: transparent;
  border: none;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-family: inherit;
  font-optical-sizing: inherit;
  font-kerning: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  -webkit-font-smoothing: inherit;
  overflow: visible;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  min-height: 2.75rem;
  text-align: center;
  text-decoration: none;
  transition-duration: 100ms;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-shadow: none;
  color: rgba(19, 0, 50, 0.75);
  display: inline-flex;
  gap: 0.2rem;
  min-width: 0px;
  padding: 1.5rem 0px;
  position: relative;
}


.css-xcov25, .css-xcov25:visited {
  color: rgba(19, 0, 50, 0.75);
}

.css-xcov25, .css-xcov25:visited {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 0.5rem;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  min-height: 2.75rem;
  min-width: 6rem;
  padding: 1rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition-duration: 100ms;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  width: 100%;
  background-color: transparent;
  box-shadow: #007bff 0px 0px 0px 0.0625rem inset;
  color: #007bff;
}

.css-1iiagsl a {
  appearance: none;
  background: transparent;
  border: none;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  font-optical-sizing: inherit;
  font-kerning: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0px;
  overflow: visible;
  width: auto;
  border-radius: 0.25rem;
  box-shadow: none;
  display: inline-block;
  min-height: auto;
  min-width: auto;
  padding: 0.25rem 0px;
  position: relative;
  color: rgba(19, 0, 50, 0.75) !important;
}

.css-1eg020q {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.css-1iiagsl a::after {
  background-color: transparent;
  bottom: 0px;
  content: "";
  height: 2px;
  left: 0px;
  position: absolute;
  width: 100%;
}

.css-1pib26a {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  text-indent: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.css-1c2xcvc, .css-1c2xcvc:visited {
  width: 100%;
}

.css-1c2xcvc, .css-1c2xcvc:visited {
  outline-offset: 2px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 0.5rem;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  min-height: 2.75rem;
  min-width: 6rem;
  padding: 1rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition-duration: 100ms;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  width: 100%;
  background-color: transparent;
  box-shadow: #007bff 0px 0px 0px 0.0625rem inset;
  color: #007bff;
}

.css-6dlccb {
  background: rgb(255, 255, 255);
  height: 0px;
  overflow: hidden;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: -0.5rem;
  visibility: hidden;
  width: 100%;
}

@media (min-width: 45rem) {
  .css-1qgc1gk {
      padding: 0px 0px 0px 1.5rem;
  }
}

.css-1qgc1gk {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  -webkit-box-align: center;
  align-items: center;
  color: rgba(19, 0, 50, 0.75);
  display: inline-flex;
  padding: 0px 0px 0px 1.5rem;
}

.css-1uyrxi7 {
  display: grid;
  gap: 4rem 0px;
  grid-template-columns: 1fr;
  overflow-x: hidden;
  padding: 1.5rem 1.5rem 8rem;
}

.css-q1kke4 {
  display: grid;
  gap: 3rem;
}

@media (min-width: 45rem) {
  .css-yq4brs {
      margin: 0;
      grid-column: span 1;
  }
}

.css-8krjsn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.375rem;
}

@media (max-width: 64rem) {
  .css-gq4wa1 {
      grid-template-columns: 1fr;
  }
}
.css-gq4wa1 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-indent: 0;
  border-top: 0.0625rem solid #CBC2FF;
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 0.75rem;
  padding-top: 1.5rem;
}

.css-1hpk4or {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #130032;
  margin: 0;
}

.css-1i6iedv, .css-1i6iedv:visited {
  color: #130032;
}

.css-1i6iedv, .css-1i6iedv:visited {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.css-1i6iedv {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 150ms ease-in-out;
  transition: color 150ms ease-in-out;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  border-radius: 0.25rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
  width: 100%;
}

@media (min-width: 45rem) {
  .css-yq4brs {
      margin: 0;
      grid-column: span 1;
  }
}

.css-yq4brs {
  grid-column: span 1 / -1;
  width: 100%;
}

.css-1i4t09n, .css-1i4t09n:visited {
  width: 100%;
}
.css-1pib26a li:last-of-type {
  grid-column: 1 / 3;
}

@media screen and (min-width: 45rem) {
    .css-1p9p3rd {
        padding: 1.5rem 1rem;
    }
}

.css-h3gokb {
  -webkit-box-flex: 666;
  flex-grow: 666;
}
.css-zzmfyc {
  appearance: none;
  background: transparent;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: 30px;
  font-family: inherit;
  font-optical-sizing: inherit;
  font-kerning: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0px;
  width: auto;
  -webkit-font-smoothing: antialiased;
  outline-offset: 2px;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  min-height: 44px;
  min-width: 44px;
  text-decoration: none;
  transition-duration: 100ms;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  border-radius: 50%;
  border: 0.0625rem solid transparent;
  color: #007bff;
}
.css-1p9p3rd {
    border-bottom: 0.0625rem solid rgba(19, 0, 50, 0.15);
    display: flex;
    gap: 0.5rem;
    padding: 1.5rem 1rem;
    width: 100%;
    z-index: 1000;
}
.css-1i4t09n, .css-1i4t09n:visited {
  outline-offset: 2px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 0.5rem;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: normal;
  margin: 0px;
  min-height: 2.75rem;
  min-width: 6rem;
  padding: 1rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition-duration: 100ms;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
  vertical-align: middle;
  width: 100%;
  background-color: #007bff;
  color: rgb(255, 255, 255);
}

// eind banner

// start register
@media (min-width: 720px) {
  .css-15ftrm {
      min-height: calc(-5rem + 100vh);
  }
}

@media (min-width: 720px) {
  .css-15ftrm {
      display: block;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .css-15ftrm {
      padding-left: 3.5rem;
  }
}

@media (min-width: 720px) {
  .css-15ftrm {
      padding-left: 1.25rem;
  }
}

.css-15ftrm {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(248, 243, 240, var(--tw-bg-opacity));
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
@media (min-width: 720px) {
  .css-1hmz7kv {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
  }
}

.css-1hmz7kv {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 6rem;
}
.css-1163voy {
  border-bottom-width: 1px;
  border-color: rgba(25, 24, 35, 0.1);
  padding-bottom: 1.5rem;
}

@media (min-width: 720px) {
  .css-rqbiwy {
      display: block;
  }
}

.css-rqbiwy {
  display: none;
}
.css-iuvg6g {
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-bottom: 0.75rem;
}
.css-vmbwkl {
  font-weight: 500;
  line-height: 124%;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 720px) {
  .css-1933b8g {
      display: none;
  }
}
.css-1933b8g {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.css-1flj9lk {
  text-align: left;
}

.css-ozibme {
  font-size: 0.75rem;
  line-height: 1rem;
}

.css-2rpr5t {
  font-weight: 500;
  line-height: 124%;
  font-size: 1.25rem;
}

.css-tryvo5 {
  display: flex;
  gap: 1rem;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.css-f3vz0n {
  font-weight: 500;
}

.css-nyjqug {
  position: relative;
  box-sizing: border-box;
  display: flex;
  height: 2.75rem;
}

.css-1u8e4p3 {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  text-align: left;
  outline: transparent solid 2px;
  outline-offset: 2px;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 0.5rem 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 0.6;
  border-color: rgba(19, 0, 50, var(--tw-border-opacity));
  border-radius: 0.5rem;
  min-width: 120px;
}

.css-cp3ir1 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 1rem;
}

.css-kfo8f7 {
  pointer-events: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.css-1gp9wld {
  height: 1.25rem;
  width: 1.25rem;
}

.css-khkg76 {
  position: absolute;
  z-index: 1000;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 0.15;
  border-color: rgba(19, 0, 50, var(--tw-border-opacity));
  border-radius: 0.25rem;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  pointer-events: none;

}
.css-1kdb0ci {
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 100%;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  word-break: normal;
  overflow-wrap: normal;
  pointer-events: auto;
  border-radius: 0.5rem;
}
.css-iy41r8 {
  max-height: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  outline: transparent solid 2px;
  outline-offset: 2px;
}
.css-rzwwa2 {
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 100%;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  word-break: normal;
  overflow-wrap: normal;
  pointer-events: auto;
  border-radius: 0.5rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 0, 255, var(--tw-ring-opacity));
}

.css-pm55z1 {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-right: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
}
.css-1as96tz {
  display: flex;
  gap: 1rem;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
  padding-bottom: 1.5rem;
}
.css-f3vz0n {
  font-weight: 500;
}
.css-o38vei {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
}

.css-mmvz9h {
  font-weight: 400;
}
.css-nyjqug {
  position: relative;
  box-sizing: border-box;
  display: flex;
  height: 2.75rem;
}
.css-1u8e4p3 {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  text-align: left;
  outline: transparent solid 2px;
  outline-offset: 2px;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 0.5rem 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 0.6;
  border-color: rgba(19, 0, 50, var(--tw-border-opacity));
  border-radius: 0.5rem;
  min-width: 120px;
}

.css-kfo8f7 {
  pointer-events: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.css-i13758 {
  font-size: 0.75rem;
  line-height: 1.5;
  --tw-text-opacity: 1;
  color: rgba(25, 24, 35, var(--tw-text-opacity));
  padding-bottom: 1.5rem;
}

.css-tc8190 {
  text-decoration: underline;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 0.125rem;
  --tw-text-opacity: 1;
  color: rgba(76, 0, 255, var(--tw-text-opacity));
}

.css-kh3xa {
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-color: rgba(25, 24, 35, 0.1);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.css-bcffy2 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.css-f3vz0n {
  font-weight: 500;
}
.css-3jh86a {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  border-width: 1px;
  box-sizing: border-box;
  display: inline-block;
  align-self: auto;
  appearance: none;
  border-style: none;
  --tw-text-opacity: 1;
  color: rgba(76, 0, 255, var(--tw-text-opacity));
  white-space: nowrap;
  padding: 0.25rem 1rem;
  height: 2.5rem;
  font-weight: 500;
  text-decoration: none;
}
.css-1mjthai {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom-width: 1px;
  border-color: rgba(25, 24, 35, 0.15);
}
.css-1eetuzj {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
}

.css-f3vz0n {
  font-weight: 500;
}
.css-taf7pb {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.css-u38nm9 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.css-2qga7i {
  text-align: right;
}

.css-u38nm9 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.css-15ylkfs {
  position: relative;
  box-sizing: border-box;
  display: flex;
  height: 4rem;
}

.css-f1u16m {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: left;
  padding-left: 1rem;
  padding-right: 2.75rem;
  padding-top: 1.25rem;
  width: 100%;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: '#ffffff';
  appearance: none;
  font-size: 1.25rem;
  line-height: 1.75rem;
  cursor: pointer;
  text-overflow: ellipsis;
}
.css-1bzl99f {
  display: flex;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: '#4e4065';
}
.css-5q8i94 {
  box-sizing: border-box;
  height: 100%;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  pointer-events: none;
  --tw-border-opacity: 0.6;
  border-color: '#13003299';
  width: 0.75rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-right-width: 0px !important;
}
.css-1wl29re {
  box-sizing: border-box;
  height: 100%;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  pointer-events: none;
  --tw-border-opacity: 0.6;
  border-color: '#13003299';
  font-size: 1.125rem;
  line-height: 1.75rem;
  flex: 0 0 auto;
  max-width: calc(100% - 1.5rem);
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

.css-1xg8pdz {
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  display: inline-block;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 50%;
  transform: var(--tw-transform);
  transform-origin: left top;
  --tw-transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  will-change: transform;
  --tw-text-opacity: 1;
  color: '#130032';
  letter-spacing: 0.01em;
  --tw-scale-x: 0.6;
  --tw-scale-y: 0.6;
  --tw-translate-x: 2px;
  max-width: calc(166.667%);
  --tw-translate-y: calc(-100% + 0.25rem);
}
.css-1xg8pdz::after {
  content: "＊";
  --tw-text-opacity: 1;
  color: #9d0c0c;
}
.css-1xg8pdz::after {
  padding-left: 1px;
  line-height: 1;
  vertical-align: super;
  font-size: 0.8em;
}
.css-fw0cfn {
  --tw-translate-x: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  display: inline-block;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 50%;
  --tw-translate-y: -50%;
  transform: var(--tw-transform);
  transform-origin: left top;
  --tw-transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  will-change: transform;
}


  .css-g9uw22 {
    box-sizing: border-box;
    height: 100%;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    pointer-events: none;
    --tw-border-opacity: 0.6;
    border-color: #13003299;
    -webkit-box-flex: 1;
    flex-grow: 1;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-left-width: 0px !important;
}

.css-1g8uukk {
  position: absolute;
  font-size: 1.5rem;
  line-height: 2rem;
  top: 1.25rem;
  right: 0.5rem;
  margin-right: 0.75rem;
  pointer-events: none;
}
.css-xv1qun {
  width: 100%;
  border-radius: 0.5rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
  text-overflow: ellipsis;
  --tw-text-opacity: 1;
  color: rgba(25, 24, 35, var(--tw-text-opacity));
  padding: 1.75rem 1rem 0.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}


// checkout 
@media (min-width: 1024px) {
  .css-1nd7z9v {
      width: calc(100% - 24rem);
  }
}

.css-1nd7z9v {
  width: 100%;
  --tw-bg-opacity: 1;
  background: #ffffff;
}

.css-1x7nnnc {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 100%;
}

@media (min-width: 1024px) {
  .css-1bg3kgs {
      padding-right: 5rem;
  }
}

@media (min-width: 720px) {
  .css-1bg3kgs {
      padding-right: 3rem;
  }
}

.css-1bg3kgs {
  height: 100%;
}

.css-bjn8wh {
  position: relative;
}



@media (min-width: 720px) {
  .css-1tycuz5 {
      padding-top: 4rem;
  }
}

.css-1tycuz5 {
  height: 100%;
}

@media (min-width: 720px) {
  .css-7glort {
      margin-bottom: 3rem;
  }
}

@media (min-width: 720px) {
  .css-7glort {
      font-weight: 300;
      line-height: 108%;
      font-size: 3rem;
  }
}

.css-7glort {
  font-weight: 400;
  line-height: 120%;
  font-size: 2rem;
  margin-bottom: 2rem;
}

fieldset {
  margin: 0;
  padding: 0;
}

.css-2bow0d {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

legend {
  padding: 0;
}

@media (min-width: 720px) {
  .css-1cbygi4 {
      margin-bottom: 3rem;
      column-gap: 1rem;
  }
}

.css-1cbygi4 {
  display: grid;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
}

@media (min-width: 1024px) {
  .css-1t2vv20 {
      word-break: normal;
      overflow-wrap: normal;
  }
}

.css-1t2vv20 {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  border-style: solid;
  display: inline-block;
  padding: 0px 1rem;
  height: 4rem;
  --tw-text-opacity: 1;
  color:'#130032';
  text-align: center;
  overflow-wrap: break-word;
  box-sizing: content-box;
  --tw-border-opacity: 1;
  border-color: #4c00ff;
  border-width: 2px;
}

button, [role="button"] {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .css-mnuzqw {
      word-break: normal;
      overflow-wrap: normal;
  }
}

.css-mnuzqw {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  border-style: solid;
  display: inline-block;
  padding: 0px 1rem;
  height: 4rem;
  border-width: 1px;
  --tw-border-opacity: 0.6;
  border-color: #13003299;
  --tw-text-opacity: 1;
  color: #130032;
  text-align: center;
  overflow-wrap: break-word;
  box-sizing: content-box;
}

@media (min-width: 720px) {
  .css-1ssmm6r {
      grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
}

@media (min-width: 1024px) {
  .css-1ssmm6r {
      word-break: normal;
      overflow-wrap: normal;
  }
}

.css-1ssmm6r {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.5rem;
  border-style: solid;
  display: inline-block;
  padding: 0px 1rem;
  height: 4rem;
  border-width: 1px;
  --tw-border-opacity: 0.6;
  border-color: #13003299;
  --tw-text-opacity: 1;
  color: #130032;
  text-align: center;
  overflow-wrap: break-word;
  box-sizing: content-box;
}

.css-mnuzqw > svg[role="img"] {
  display: inline-block;
  vertical-align: top;
  font-size: 1.5em;
}

.css-dx8hht {
  height: 1.5rem;
  width: 5rem;
}

.css-z2gjx1 {
  margin-left: 0.5rem;
}

.css-ho7zc7 {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  margin-bottom: 1rem;
}




.css-1bzl99f {
  display: flex;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: #4e4065;
}

.css-5q8i94 {
  box-sizing: border-box;
  height: 100%;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  pointer-events: none;
  --tw-border-opacity: 0.6;
  border-color: #13003299;
  width: 0.75rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-right-width: 0px !important;
}

.css-1wl29re {
  box-sizing: border-box;
  height: 100%;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  pointer-events: none;
  --tw-border-opacity: 0.6;
  border-color: #13003299;
  font-size: 1.125rem;
  line-height: 1.75rem;
  flex: 0 0 auto;
  max-width: calc(100% - 1.5rem);
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}



.css-fw0cfn::after {
  padding-left: 1px;
  line-height: 1;
  vertical-align: super;
  font-size: 0.8em;
}



.css-fw0cfn::after {
  content: "＊";
  --tw-text-opacity: 1;
  color: #9d0c0c;
}

.css-bjn8wh {
  position: relative;
}



.css-1qdl6m2 {
  width: 100%;
  border-radius: 0.5rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
  text-overflow: ellipsis;
  --tw-text-opacity: 1;
  color: rgba(25, 24, 35, var(--tw-text-opacity));
  padding: 1.75rem 7rem 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.css-1bzl99f {
  display: flex;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgba(78, 64, 101, var(--tw-text-opacity));
}

.css-5q8i94 {
  box-sizing: border-box;
  height: 100%;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  pointer-events: none;
  --tw-border-opacity: 0.6;
  border-color: rgba(19, 0, 50, var(--tw-border-opacity));
  width: 0.75rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-right-width: 0px !important;
}

.css-1wl29re {
  box-sizing: border-box;
  height: 100%;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  pointer-events: none;
  --tw-border-opacity: 0.6;
  border-color: rgba(19, 0, 50, var(--tw-border-opacity));
  font-size: 1.125rem;
  line-height: 1.75rem;
  flex: 0 0 auto;
  max-width: calc(100% - 1.5rem);
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

.css-fk62m5 {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  gap: 0.25rem;
  z-index: 10;
  pointer-events: none;
}

.css-1pdkwl8 {
  width: 2rem;
  height: 2rem;
}

@media (min-width: 720px) {
  .css-1jt7e93 {
      grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
}

.css-1jt7e93 {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  margin-top: 1rem;
}

@media (min-width: 720px) {
  .css-1t9d6tq {
      grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
}

.css-1t9d6tq {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  margin-top: 1rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 720px) {
  .css-1hx9cb8 {
      margin-top: 2.25rem;
  }
}

.css-1hx9cb8 {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 224, var(--tw-border-opacity));
  margin-top: 1.25rem;
  padding-top: 0.25rem;
}

.css-125vr0n {
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  letter-spacing: 0.025em;
}

.css-tc8190 {
  text-decoration: underline;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 0.125rem;
  --tw-text-opacity: 1;
  color: rgba(76, 0, 255, var(--tw-text-opacity));
}


@media (min-width: 720px) {
  .css-pjhtp2 {
      width: 16rem;
      min-width: max-content;
  }
}

.css-pjhtp2 {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
  text-align: center;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  display: inline-block;
  --tw-border-opacity: 1;
  border-color: rgba(76, 0, 255, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(76, 0, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  padding: 0.75rem 1rem;
  height: 3.5rem;
  min-width: 100%;
  border-radius: 0.5rem;
}
.css-1m3b0b0 {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 224, var(--tw-border-opacity));
  //margin-top: 3rem;
  margin-bottom: 0.75rem;
  // padding-top: 0.75rem;
}
.css-1rrra7o {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.css-1u9mbv2 {
  align-self: flex-start;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  float: left;
  appearance: none;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.125rem;
  background-position: center center;
  background-repeat: no-repeat;
  border-width: 2px;
  border-style: solid;
  --tw-border-opacity: 0.6;
  border-color: rgba(19, 0, 50, var(--tw-border-opacity));
  cursor: pointer;
  outline: transparent solid 2px;
  outline-offset: 2px;
  background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221em%22%20height%3D%221em%22%20role%3D%22img%22%20aria-hidden%3D%22true%22%20color%3D%22white%22%3E%3Cpath%20d%3D%22m20.5%205.117-9.389%2014.434a.969.969%200%200%201-1.512.153L3.5%2013.455l1.39-1.425%205.251%205.383L18.865%204%2020.5%205.117z%22%20fill%3D%22currentColor%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
  background-size: 100% 100%;
}

.css-1wvofwg {
  padding-left: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.css-tc8190 {
  text-decoration: underline;
    outline: transparent solid 2px;
    outline-offset: 2px;
    border-radius: 0.125rem;
    --tw-text-opacity: 1;
    color: #4c00ff;
}

.css-125vr0n {
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  letter-spacing: 0.025em;
}
.css-2ktvss {
  margin-top: 0.75rem;
  display: flex;
  border-radius: 0.5rem;
  -webkit-box-align: center;
  align-items: center;
  width: max-content;
  --tw-bg-opacity: 1;
  background-color: rgba(248, 243, 240, var(--tw-bg-opacity));
}

.css-yxozb8 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(19, 0, 50, var(--tw-text-opacity));
}

.css-19wypfv {
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
}


.b3-payment-methods-instrument-details-section {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  max-width: 400px;
  margin: auto;
}

.b3-payment-methods-section-title-form {
  margin-bottom: 16px;
}

.b3-form-header-title-text {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.b3-payment-methods-card {
  display: flex;
  flex-direction: column;
}



.b3id-instrument-details-label {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.b3id-info-message-component {
  font-size: 14px;
  color: #666666;
}

.b3id-info-message-html {
  margin: 0;
}

.b3-instrument-details-actions-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}



.b3id-instrument-details-action a {
  text-decoration: none;
}

.b3id-instrument-details-action:hover {
  background-color: #f0f0f0;
}

.b3id-instrument-details-default-action {
  margin-left: 8px;
}

.b3id-link-display-text {
  font-size: 14px;
  color: #007bff;
}

.b3id-link-display-text span {
  font-weight: bold;
}



.b3-ripple-container {
  position: relative;
}

.b3-ripple-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.3s;
}

.b3-ripple-container:hover::before {
  transform: scale(1);
}

.b3id-instrument-details-default-actions {
  display: flex;
  justify-content: flex-start; /* Align items to the start of the container */
  gap: 10px; /* Space between items */
}

.b3id-instrument-details-action {
  flex: 1; /* Optional: make actions grow to fill available space */
}

.b3id-widget-link {
  display: flex;
  align-items: center; /* Center items vertically */
  text-decoration: none; /* Remove underline from links */
}


.b3-instrument-details-info-panel {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 20px; /* Space between image and details */
}

.b3-instrument-details-header {
  flex: 1; /* Allow the header to grow and take up available space */
}

.b3id-image-with-caption {
  flex-shrink: 0; /* Prevent the image container from shrinking */
}

.b3-image {
  width: 50px; /* Set the width of the image */
  height: auto; /* Maintain aspect ratio */
}

/*----------- BUTTON ----------*/
.btn-holder {
  margin: 0 auto;
}



.card-header-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #eaeef8;
}

.card-title-group-c {
  display: flex;
  flex-direction: column;
}

.card-title-c {
  margin: 0;
  font-size: 1.5rem;
}

.card-title-desc-c {
  margin: 0;
  font-size: 1rem;
  color: #666;
}

.modal-button-c {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #0056b3;
}


/* Basic Rules */
.switch_c input { 
  display:none;
}
.switch_c {
  display:inline-block;
  width: 40px;
  height: 20px;
  transform:translateY(50%);
  position:relative;
}
/* Style Wired */
.slider_c {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  border-radius:30px;
  box-shadow:0 0 0 2px #777, 0 0 4px #777;
  cursor:pointer;
  border:2px solid #b2b2b2;
  overflow:hidden;
   transition:.4s;
}
.slider_c:before {
  position:absolute;
  content:"";
  width:50%;
  height:100%;
  background:#777;
  border-radius:30px;
  transform:translateX(0px);
  transition:.4s;
}

input:checked + .slider_c:before {
  transform:translateX(16px);
  background:limeGreen;
}
input:checked + .slider_c {
  box-shadow:0 0 0 2px limeGreen,0 0 2px limeGreen;
}

/* Style Flat */
.switch_c.flat_c .slider_c {
box-shadow:none;
}
.switch_c.flat_c .slider_c:before {
background:rgb(161, 161, 161);
}
.switch_c.flat_c input:checked + .slider_c:before {
  background:#ffffff;
}
.switch_c.flat_c input:checked + .slider_c {
background:limeGreen;
}

.checkbox-group_c {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns */
  gap: 10px; /* Adjust the gap between checkboxes as needed */
}

.permission-checkbox_c {
  display: flex;
  align-items: center;
}

.permission-checkbox_c input {
  margin-right: 10px;
}

//footer


.css-mg5nk5-FOOTER_MAIN_ROW_NO_LOGO {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  list-style-type: none;
  margin: 0px 0px;
  position: relative;
  bottom: 24px;
}

.css-57uoyj-FOOTER_LINK_LIST {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  padding: inherit;
}

.css-1lvmctb-FOOTER_LINK {
  color: rgb(102, 102, 102);
  text-decoration: none;
}

.css-57uoyj-FOOTER_LINK_LIST [data-link]::after {
  content: "|";
  margin-inline-start: 8px;
}


.dropzone {
    min-height: 90px;
    border: 2px dashed var(--bs-border-color);
    background: var(--bs-custom-white);
    border-radius: 6px;
}

.card_pay_plane {
  background: #fff;
  border: 1px solid #e2ebf6;
  border-radius: 0.25em;
  cursor: pointer;
  display: inline-flex;
  padding: 3px;
  position: relative;
  transition: all 0.2s;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}


.card_pay_plane:hover {
border-color: #c4d1e1;
box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.15);
transform: translate(-4px, -4px);
}

.card__image_pay_plane {
border-radius: 0.25em;
height: 6em;
min-width: 6em;
}

.card__content_pay_plane {
flex: auto;
padding: 0 1em;
display: inline-flex;
}
.checkbox-control_pay_plane{
      display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: background-color 0.3s ease;
  background-color: white;
}


.time_cost{
  padding-right: 15px;
  position: relative;
  top: 7px;
}
.card_pay_plane h2 {
font-weight: 700;
margin: 0;
}

.card_pay_plane p {
color: #546e7a;
margin: 0;
}
.check_Driver_deatails_plane{
color: #1d8f00;
}
/* Checkbox Styles */
.checkbox_pay_plane {
  cursor: pointer;
  background: #e2ebf6;
  border-radius: 50%;
  height: 1em;
  margin: 0;
  margin-left: auto;
  flex: none;
  outline: none;
  position: relative;
  transition: all 0.2s;
  width: 2em;
  top: 7px;
  
}
.checkbox_pay_plane:after {
border: 2px solid #fff;
border-top: 0;
border-left: 0;
content: "";
display: block;
height: 1em;
left: 0.625em;
position: absolute;
top:-2px;
transform: rotate(45deg);
width: 0.5em;
}
.checkbox_pay_plane:focus {
box-shadow: 0 0 0 2px rgba(100, 193, 117, 0.6);
}
.checkbox-pay_plane:checked {
background: #64c175;
border-color: #64c175;
}

.checkbox-control__target_pay_plane {
bottom: 0;
cursor: pointer;
left: 0;
opacity: 0;
position: absolute;
right: 0;
top: 0;
}
.header-profile-user_plane {
  height: 26px;
  width: 26px;
  background-color: #f6f6f6;
  padding: 3px; }

  .medewerkers{
    overflow-y: auto;
    max-height: 200px;
  }

  .select_all{
    position: relative;
    display: flex;
    justify-content: center;
    top: 7px;
  }

  button.trash {
    float: left;
    border: none;
    position: absolute;
    z-index: 1;
    background: #e9e9e9;
    left: 10px;
    margin: 7px 0px 0px 2px;
    font-size: 15px;
    opacity: 1.5;
    padding: 5px;
    border-radius: 12px;
    color: black;
    font-weight: 500;
    opacity: 2px;
}

#drop-region {
  border-radius: 8px;
  border-bottom-left-radius: unset;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  display: none;
}

#image-preview {
  overflow-x: auto;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Lightbox Image */
.lightbox-img {
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

/* Optional: Add some transition to the image in the lightbox */
.lightbox-img {
  transition: transform 0.3s ease-in-out;
}

.lightbox-img:hover {
  transform: scale(1.05);
}

.list_details {
  margin: 0px 0px 6px 0px;
}

.wrapperPdf {
  font-size: 10px;
  width: 575px;
  margin: auto;
  background-color: #ffffff;
  color: #000;
  border-radius: 5px;
  padding: 10px;
  margin-top: 15px;
}


.personal-contact-container {
  max-width: 600px;
  margin: auto;
}



.table-container {
  border-collapse: collapse;
  width: 100%;
}

.table-container_cv {
  border-collapse: collapse;
  width: 100%;
}
.table-row {
  display: flex;
  border: 1px solid black;
}

.table-cell {
  flex: 1;
  // padding: 0px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: center; 
}

.table-cell label {
  margin: 0 10px; /* Adds horizontal spacing between radio buttons */
  display: flex;
  align-items: center; /* Vertically center the radio button and text */
}
.table-cell strong {
  display: block;
}

.table-row:nth-child(odd) .table-cell {
  background-color: #f9f9f9;
}

.table-cell input[type="radio"] {
  margin-right: 5px; /* Space between radio button and text */
}

.inline-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.inline-container .spanright {
  display: flex;
  align-items: center;
}
.partone {
  position: relative;
  display: inline-block;
}

.parttow {
  display: inline-block;
  padding-left: 50px;
  margin-left: 46px;
  background: #e2f0d9;
}

.partthree {
  padding-left: 10px;
}


.inline-container a {
  text-decoration: none;
  color: #000;
}


.input-group {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Adjust gap between inputs as needed */
  flex-wrap: wrap; /* Allows the inputs to wrap on smaller screens */
}

.input-group label {
  flex: 1;
  margin: 0 5px; /* Margin around each label */
}

.input-group input {
  width: 100%; /* Ensures inputs fill the available space in their container */
  box-sizing: border-box; /* Ensures padding and border are included in width */
}

.checkbox-group {
  display: flex;
  flex-direction: column; /* Stack checkboxes vertically */
  align-items: center;
}

.checkbox-group label {
  margin-bottom: 5px; /* Margin between checkboxes */
}
.css-3nkkse{
  position: absolute;
    width: 180px;
    left: 183px;
    background: #020202e0;
    color: white;
    margin: 5px;
    padding: 5px;
    z-index: 1000;
   
}
.qouteStyle{
  margin-left: 6px;
    margin-top: -3px;
}
.scrollable-tooltip {
  max-height: 150px; /* Limit the height */
  overflow-y: auto;  /* Enable vertical scroll */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  
}

.caption {
  color: #000000;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.25rem;
  text-align: center;
}

.punchloader {
  width: 90px;
  position: relative;
  height: 100px;
  margin: 1rem auto;
}
.punchloader__item {
  position: absolute;
  top: 50%;
}
.punchloader__item:first-of-type {
  left: 0;
  margin-right: 8px;
}
.punchloader__item:last-of-type {
  right: 0;
  margin-left: 8px;
}
.punchloader__item::before {
  content: '';
  width: 30%;
  height: 60%;
  bottom: 0;
  position: absolute;
  left: 50%;
  border-left: 1px solid #251e26;
  border-right: 1px solid #251e26;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-background-clip: content;
  -moz-background-clip: content;
  background-clip: content-box;
}
.punchloader__item::after {
  content: '';
  width: 80%;
  height: 30%;
  position: absolute;
  background-color: inherit;
  bottom: calc(-30% - 2px);
}
.punchloader__item:last-of-type::after {
  left: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.punchloader__item:first-of-type::after {
  right: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.punchloader__item:first-of-type {
  -webkit-animation: punch-start-small 1s infinite ease-out, punchcolor 4s infinite;
  -moz-animation: punch-start-small 1s infinite ease-out, punchcolor 4s infinite;
  -o-animation: punch-start-small 1s infinite ease-out, punchcolor 4s infinite;
  -ms-animation: punch-start-small 1s infinite ease-out, punchcolor 4s infinite;
  animation: punch-start-small 1s infinite ease-out, punchcolor 4s infinite;
}
.punchloader__item:last-of-type {
  -webkit-animation: punch-start-big 1s infinite ease-out, punchcolor 4s infinite;
  -moz-animation: punch-start-big 1s infinite ease-out, punchcolor 4s infinite;
  -o-animation: punch-start-big 1s infinite ease-out, punchcolor 4s infinite;
  -ms-animation: punch-start-big 1s infinite ease-out, punchcolor 4s infinite;
  animation: punch-start-big 1s infinite ease-out, punchcolor 4s infinite;
}
@-moz-keyframes punch-start-big {
  0% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
  50% {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  100% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
}
@-webkit-keyframes punch-start-big {
  0% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
  50% {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  100% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
}
@-o-keyframes punch-start-big {
  0% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
  50% {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  100% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
}
@keyframes punch-start-big {
  0% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
  50% {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  100% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
}
@-moz-keyframes punch-start-small {
  0% {
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  50% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
  100% {
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
}
@-webkit-keyframes punch-start-small {
  0% {
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  50% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
  100% {
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
}
@-o-keyframes punch-start-small {
  0% {
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  50% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
  100% {
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
}
@keyframes punch-start-small {
  0% {
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
  50% {
    width: 50px;
    height: 50px;
    -webkit-transform: translateY(-50%) rotate(0deg);
    -moz-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    -ms-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    border-radius: 4px;
  }
  100% {
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
    width: 15px;
    height: 15px;
    border-radius: 1px;
  }
}
@-moz-keyframes punchcolor {
  0% {
    background-color: #44ced3;
  }
  25% {
    background-color: #44d377;
  }
  50% {
    background-color: #dedb3e;
  }
  75% {
    background-color: #dea93e;
  }
  100% {
    background-color: #44ced3;
  }
}
@-webkit-keyframes punchcolor {
  0% {
    background-color: #44ced3;
  }
  25% {
    background-color: #44d377;
  }
  50% {
    background-color: #dedb3e;
  }
  75% {
    background-color: #dea93e;
  }
  100% {
    background-color: #44ced3;
  }
}
@-o-keyframes punchcolor {
  0% {
    background-color: #44ced3;
  }
  25% {
    background-color: #44d377;
  }
  50% {
    background-color: #dedb3e;
  }
  75% {
    background-color: #dea93e;
  }
  100% {
    background-color: #44ced3;
  }
}
@keyframes punchcolor {
  0% {
    background-color: #44ced3;
  }
  25% {
    background-color: #44d377;
  }
  50% {
    background-color: #dedb3e;
  }
  75% {
    background-color: #dea93e;
  }
  100% {
    background-color: #44ced3;
  }
}


.css-rapors32 .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-title {
  flex-grow: 1; /* Allows the title to take up the remaining space */
  font-weight: bold; /* You can customize the font style if needed */
}

.select-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px 0;
}

.select-group {
  position: relative;
  width: 150px; /* Adjust width based on design */
}

.gcdc-gate .gcdc-form-render.gcdc-form-double-column .gcdc-form-group, .gcdc-gate .gcdc-content-render.gcdc-form-double-column .gcdc-form-group {
  margin-left: 1%;
  margin-right: 1%;
  vertical-align: top;
  font-size: 0.75rem;
  font-weight: 400 !important;
  letter-spacing: 0.2px;
}

.gcdc-gate .gcdc-form-render>div.gcdc-form-group::before, .gcdc-gate .gcdc-form-render>div.gcdc-form-group::after, .gcdc-gate .gcdc-content-render>div.gcdc-form-group::before, .gcdc-gate .gcdc-content-render>div.gcdc-form-group::after {
  content: " ";
  display: table;
}

.gcdc-gate * {
  font-family: "DSIndigo", Helvetica, Arial, sans-serif;
  line-height: inherit;
  color: #130032;
}

.gcdc-gate * {
  font-size: 14px;
  line-height: 1.14;
}


.gcdc-gate * {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 14px;
  font-family: Arial, sans-serif;
  text-align: left;
  color: #000;
  box-sizing: border-box;
}

.gcdc-form-render .gcdc-form-message p {
  color: #130032bf !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  display: block !important;
  letter-spacing: -0.25px;
}


@media (min-width: 992px) {
  .gcdc-form-render .gcdc-form-message p {
      font-size: 24px !important;
      letter-spacing: -0.25px;
  }
}

.gcdc-gate .gcdc-form-render>div.gcdc-form-group::before, .gcdc-gate .gcdc-form-render>div.gcdc-form-group::after, .gcdc-gate .gcdc-content-render>div.gcdc-form-group::before, .gcdc-gate .gcdc-content-render>div.gcdc-form-group::after {
  content: " ";
  display: table;
}

.gcdc-form-render .gcdc-form-message h1 {
  font-size: 2.5rem !important;
  letter-spacing: -0.5px !important;
  line-height: 1.2 !important;
  font-weight: 400 !important;
  color: #130032;
}

.css-9oxnp8{
  visibility: visible;
  pointer-events: auto;
  left: 0px;
  position: absolute;
  top: 100%;
  width: 100%;
}



@media (min-width: 1024px) {
  .lg\:shadow-30 {
      --tw-shadow: inset 0px 2px 25px 0 rgba(0, 0, 0, .104);
      --tw-shadow-colored: inset 0px 2px 25px 0 var(--tw-shadow-color);
      -webkit-box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
      box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
      -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  }
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.overflow-hidden {
  overflow: hidden;
}


.h-full {
  height: 100%;
}

.container {
  width: 100%;
}

@media (min-width: 1024px) {
  .lg\:pt-8 {
      padding-top: 2rem;
  }
}

@media (min-width: 1024px) {
  .lg\:pb-11 {
      padding-bottom: 2.75rem;
  }
}


@media (min-width: 1024px) {
  .lg\:px-4 {
      padding-left: 1rem;
      padding-right: 1rem;
  }
}

.max-w-6xl {
  max-width: 72rem;
}


.mx-auto {
  margin-left: auto;
  margin-right: auto;
}


@media (min-width: 1024px) {
  .container {
      max-width: 1024px;
  }
}


.items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (min-width: 1024px) {
  .lg\:pb-0 {
      padding-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:px-0 {
      padding-left: 0;
      padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:w-4\/12 {
      width: 33.333333%;
  }
}

@media (min-width: 1024px) {
  .lg\:mr-4 {
      margin-right: 1rem;
  }
}

@media (min-width: 1024px) {
  .lg\:mr-0 {
      margin-right: 0;
  }
}

.w-full {
  width: 100%;
}


.shadow-10 {
  --tw-shadow: 0 5px 20px 0 rgba(0, 0, 0, .15);
  --tw-shadow-colored: 0 5px 20px 0 var(--tw-shadow-color);
}

.shadow-1, .shadow-10 {
  -webkit-box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .md\:text-left {
      text-align: left;
  }
}

@media (min-width: 768px) {
  .md\:flex-col, .md\:flex-row {
      -webkit-box-direction: normal;
  }
}

@media (min-width: 768px) {
  .md\:flex-row {
      -webkit-box-orient: horizontal;
      -ms-flex-direction: row;
      flex-direction: row;
  }
}


@media (min-width: 768px) {
  .md\:text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem;
  }
}

@media (min-width: 640px) {
  .sm\:text-lg {
      font-size: 18px;
  }
}

.font-extrabold {
  font-weight: 800;
}

@media (min-width: 640px) {
  .sm\:text-base {
      font-size: 1rem;
      line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:space-y-0>:not([hidden])~:not([hidden]) {
      --tw-space-y-reverse: 0;
      margin-bottom: calc(0px* var(--tw-space-y-reverse));
      margin-top: calc(0px*(1 - var(--tw-space-y-reverse)));
  }
}


.space-y-8>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(2rem* var(--tw-space-y-reverse));
  margin-top: calc(2rem*(1 - var(--tw-space-y-reverse)));
}

.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}


.kb-page--index main {
  margin-bottom: -6.675em;
  padding-bottom: 6.675em;
}

.kb-theme--minimal .kb-index {
  padding: 2rem 0 0;
}

@media screen and (min-width: 740px) {
  .kb-index--minimal {
      max-width: 700px;
  }
}

@media screen and (min-width: 541px) {
  .kb-index {
      max-width: 680px;
  }
}


.kb-index--default, .kb-index--cards, .kb-index--content, .kb-index--minimal {
  margin-left: 0;
}


.kb-index {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  margin: 0 auto;

}

.kb-index--minimal .kb-index__category {
  -webkit-box-flex: 0;
  -ms-flex: 0 340px;
  flex: 0 340px;
  position: relative;
  padding: 1.75rem 2rem 2rem 4rem;
  margin-bottom: 1.25rem;
  border-radius: 3px;
  color: #2D3E50;
}
@media screen and (min-width: 768px) {
  .kb-index__category--3col {
      -webkit-box-flex: 0;
      -ms-flex: 0 330px;
      flex: 0 330px;
  }
}

.kb-index__category--3col {
  padding: 0 0 4.25rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 100%;
  flex: 0 100%;
}

.kb-index__category {
  color: #33475b;
}
.kb-index--minimal .kb-index__category-icon, .kb-index--minimal .kb-index__category-empty-preview-icon {
  font-size: 24px;
  position: absolute;
  left: 1rem;
  top: 2rem;
  width: 28px;
  height: auto;
  max-height: 100%;
}

.kb-index__see-all-link, .kb-index__category-description {
  color: #516F90;
}

.kb-index--minimal .kb-index__category-description {
  margin-top: .625rem;
}


@media screen and (min-width: 768px) {
  .kb-category-menu {
      display: block;
      max-width: 15rem;
      position: relative;
  }
}

.kb-category-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.kb-category-menu li {
  margin: 0;
  padding: 0;
  line-height: 1rem;
  font-size: .875rem;
}

.kb-category-menu li a {
  display: flex;
  padding: .75rem;
  color: #33475b;
  position: relative;
}

.kb-category-menu li a div.kb-category-menu-option {
  width: 196px;
}

.kb-category-menu li a div {
  display: inline-block;
}

.kb-category-menu-option__color-bar {
  display: contents;
  position: absolute;
  left: -3px;
  top: 4px;
  height: 30px;
  width: 3px;
}

.kb-category-menu li a div {
  display: inline-block;
}

.kb-category-menu ul li.open svg {
  transform: rotate(0deg);
}

html .kb-category-menu a svg {
  transform: rotate(-90deg);
}


.kb-category-menu ul ul li {
  font-size: .75rem;
}

.kb-category-menu ul li.open > ul {
  display: block;
  
}

.kb-category-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.kb-category-menu li.active > a {
  border-radius: 2px;
  font-weight: bold;
}

.kb-category-menu ul ul li a {
  padding-left: 2rem;
}

.kb-category-menu li a {
  display: flex;
  padding: .75rem;
  color: #33475b;
  position: relative;
}

#ka-feedback-form #ka-feedback-post-submit-message, #ka-feedback-form #ka-feedback-rating-input-wrapper {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 30px 0;
} 
 #ka-feedback-form #ka-feedback-rating-input-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
}

#ka-feedback-form .ka-feedback-rating-buttons, #ka-feedback-form .ka-feedback-rating-question {
  display: inline-block;
  margin-left: 8px;
}

#ka-feedback-form .ka-feedback-label-light {
  color: #33475b;
  font-size: 14px;
  font-weight: inherit;
}

#ka-feedback-form .ka-feedback-rating-buttons, #ka-feedback-form .ka-feedback-rating-question {
  display: inline-block;
  margin-left: 8px;
}

#ka-feedback-form button {
  height: 32px;
  border: 1px solid #cbd6e2;
  border-radius: 3px;
  background-color: #eaf0f6;
  color: #425b76;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
}

#ka-feedback-form button.ka-feedback-rating-button {
  width: 90px;
}

#ka-feedback-form button.ka-feedback-rating-button span {
  font-family: Helvetica !important;
  font-size: 12px !important;
  line-height: 14px;
}


@media (min-width: 1024px) {
  .lg\:space-x-reverse>:not([hidden])~:not([hidden]) {
      --tw-space-x-reverse: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:space-x-8>:not([hidden])~:not([hidden]) {
      --tw-space-x-reverse: 0;
      margin-left: calc(2rem*(1 - var(--tw-space-x-reverse)));
      margin-right: calc(2rem* var(--tw-space-x-reverse));
  }
}

@media (min-width: 1024px) {
  .lg\:px-0 {
      padding-left: 0;
      padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:w-9\/12 {
      width: 75%;
  }
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .md\:h-\[262px\] {
      height: 262px;
  }
}

@media (min-width: 640px) {
  .sm\:h-48 {
      height: 12rem;
  }
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.h-40 {
  height: 10rem;
}

@media (min-width: 640px) {
  .sm\:px-8 {
      padding-left: 2rem;
      padding-right: 2rem;
  }
}

.pt-3 {
  padding-top: .75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-2 {
  padding-bottom: 1rem !important;
}
.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}
.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}
@media (min-width: 1024px) {
  .lg\:text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem;
  }
}

@media (min-width: 640px) {
  .sm\:text-lg {
      font-size: 18px;
  }
}

.text-dark-30 {
  --tw-text-opacity: 1;
  color: #101828;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font-extrabold {
  font-weight: 800;
}

.mb-1 {
  margin-bottom: .25rem;
}

@media (min-width: 640px) {
  .sm\:text-xs {
      font-size: .75rem;
      line-height: 1rem;
  }
}

.text-gray-70 {
  --tw-text-opacity: 1;
  color: #858585;
}

.text-\[10px\] {
  font-size: 10px;
}

.space-x-3>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(.75rem*(1 - var(--tw-space-x-reverse)));
  margin-right: calc(.75rem* var(--tw-space-x-reverse));
}

@media (min-width: 640px) {
  .sm\:text-\[10px\] {
      font-size: 10px;
  }
}

.text-blue-60 {
  --tw-text-opacity: 1;
  color: #0099ff;
}

.font-semibold {
  font-weight: 600;
}

.text-\[8px\] {
  font-size: 8px;
}

.py-1 {
  padding-bottom: .25rem;
  padding-top: .25rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}
.bg-blue-10 {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 247, 255, var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  .lg\:flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.list-none {
  list-style-type: none;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: #98a2b3;
}

.w-12 {
  width: 3rem;
}

.h-12 {
  height: 3rem;
}


.language-switcher {
  display: flex;
  gap: 10px;
}

.language-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  color: #333;
  transition: color 0.3s ease;
}

.language-button.active {
  font-weight: bold;
  color: #007bff; /* Active language color */
}

.language-button:hover {
  color: #007bff;
}
.pb-12 {
  padding-bottom: 3rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}


.pt-12 {
  padding-top: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.gap-24 {
  gap: 6rem;
}

.grid {
  display: grid;
}


.card-hover {
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;
}

.card-hover:hover {
  border-color: #31A7F0;
}

.new-btn_group {
  opacity: 0;
  transition: opacity 0.3s;
}

.parent-container:hover .new-btn_group .tooltip-text  {
  opacity: 1;
}



.tooltip-text {
  opacity: 0;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  transition: opacity 0.3s;
  pointer-events: none;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Optional: add styles for the slider */
.slider-content {
  max-height: 0; /* Hidden by default */
  overflow: hidden;
  transition: max-height 0.5s ease; /* Smooth open/close animation */
}

.slider-content.open {
  max-height: 500px; /* Height can be adjusted based on content */
}

.select-employee__title{
  font-style: italic;
  color: inherit !important;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select-employee__value{
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flex-grow, .grow {
  flex-grow: 1 !important;
}

.min-w-\[120px\] {
  min-width: 120px !important;
}

.tab-item {
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid transparent;
}

.tab-item.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.menu-content {
  padding: 20px;
  border-top: 1px solid #ddd;
}

.spinner-overlay-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent white background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure it’s above other content */
}

.spinner-modal {
  border: 5px solid #f3f3f3; /* Light gray */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}