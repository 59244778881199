.table-container {
    height: 320px;
    overflow-y: auto;
    border: 1px solid #ccc; /* Optional: Adds a border for table container */
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 14px;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 8px 12px;
    border: 1px solid #ccc;
    white-space: nowrap;
    color: #000000;
  }
  
  .custom-table th {
    background-color: #f4f9fd;
    color: #4c4b4b;
    position: sticky;
    top: 0; /* Keeps headers fixed while scrolling */
    z-index: 1;
  }
  
  .custom-table td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px; /* Adjust as needed */
  }
  
  .ellipsis-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .table-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
  
  .table-container::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }
  