.ftco-section {
    padding: 7em 0;
}

 section {
    display: block;
}

.table-wrap {
    overflow-x: scroll;
}


.checkbox-primary {
    color: #40bfc1;
}

.checkbox-wrap {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-wrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


.checkmark {
    position: absolute;
    top: 0;
    left: 0;
}

.checkbox-primary input:checked ~ .checkmark:after {
    color: #40bfc1;
}

.checkbox-wrap input:checked ~ .checkmark:after {
    display: block;
    content: "\f14a";
    font-family: "FontAwesome";
    color: rgba(0, 0, 0, 0.2);
}

@media (prefers-reduced-motion: reduce) {
    .checkmark:after {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}


.checkmark:after {
    content: "\f0c8";
    font-family: "FontAwesome";
    position: absolute;
    color: rgba(0, 0, 0, 0.1);
    font-size: 20px;
    margin-top: -14px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.table tbody td .img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}


.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}


.doc-upload-container {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }


  .doc-type-select {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .doc-file-input {
    font-size: 14px;
  }


  .upload-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-btn:hover {
    background-color: #0056b3;
  }

  